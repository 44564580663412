import React, { useEffect, useState } from 'react'
import MDEditor from '@uiw/react-md-editor';
import { MdPublish } from 'react-icons/md'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../../Supabase/Config';

const AddBlog = () => {

    const [blogData, setBlogData] = useState({
        title: "",
        description: "",
        imageUrl: "",
        publisherName: "",
        publisherEmail: ""
    })
    const [markdownData, setMarkdownData] = useState('');
    const [data, setData] = useState([])

    const handleAddBlog = async (e) => {
        e.preventDefault()
        console.log("Button Trrigred")
        if(markdownData.length === 0){
           return toast.warning('Add data in Markdown Input field!')
        }
        try {
            const { data, error } = await supabase
                .from('blog-data')
                .insert({
                    title: blogData.title,
                    description: blogData.description,
                    markdownData: markdownData,
                    imageUrl: blogData.imageUrl,
                    publisherName: blogData.publisherName,
                    publisherGithub: blogData.publisherGithub,
                    publisherLinkedin: blogData.publisherLinkedin,
                    publisherTwitter: blogData.publisherTwitter,

                })
            if(error) {
                toast.error('Something Went Wrong!')
            }
            else {
                toast.success('Data successfuly added!')
                setBlogData({
                    title: "",
                    description: "",
                    imageUrl: "",
                    publisherName: "",
                    publisherGithub: "",
                    publisherLinkedin: "",
                    publisherTwitter: ""
                })
                setMarkdownData('')
            }
        }
        catch (error) {
            console.log(error)
            toast.error('Server error!')
        }
    }
    const handleChange = (e) => {
        setBlogData({ ...blogData, [e.target.name]: e.target.value })
    }

    const getData = async () => {
        let { data, error } = await supabase
            .from('blog-data')
            .select('*')
        setData(data)
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className='max-w-[1700px] m-auto pb-16 pt-8 bg-[#002E5A] text-white border-b-2 border-whitesmoke'>
                <h2 className=' text-center text-3xl font-bold mb-8 text-white'>Add Your Blog</h2>
                <form className="w-11/12 m-auto " onSubmit={handleAddBlog}>
                    <div className=' flex flex-row justify-between md:flex-col sm:flex-col'>
                        <div className="w-[50%] md:w-full sm:w-full h-[60vh] md:h-[40vh] sm:h-[40vh] mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-medium italic my-2" for="grid-first-name">
                                Write something to post: What is open-source? And how to get started!! BTW We do support Markdown format for blogpost content.                            </label>
                            <div className='h-[60vh] md:h-[33vh] sm:h-[33vh] bg-slate-400'>
                                <MDEditor
                                    className='markdownContainer'
                                    value={markdownData}
                                    onChange={setMarkdownData}
                                />
                            </div>

                            <p className="text-cyan-200 text-xs italic mt-1">Please fill out this field.</p>
                        </div>
                        <div className='w-[45%] md:w-full sm:w-full md:mt-6 sm:mt-6'>
                            <div>
                                <h1 className='text-2xl font-semibold mb-3'>Blog Data</h1>
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 mb-4 ">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-first-name">
                                        Title of Blog
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text"
                                        required
                                        value={blogData.title}
                                        name='title'
                                        onChange={handleChange}
                                        placeholder="My Blog..." />
                                </div>
                                <div className="w-full px-3 mb-4">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Blog Description
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Description..."
                                        required
                                        value={blogData.description}
                                        name='description'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full px-3">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Image link for the Blog
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text"
                                        placeholder="Image link URL..."
                                        required
                                        value={blogData.imageUrl}
                                        name='imageUrl'
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <h1 className='text-2xl font-semibold my-3'>Publisher's Details</h1>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-2 sm:flex sm:flex-col -mx-3 mb-6">

                                <div className="w-full px-3 mb-4">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Publisher Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Publisher Name..."
                                        required
                                        value={blogData.publisherName}
                                        name='publisherName'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full px-3 mb-4">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Publisher GitHub Profile
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="GitHub Profile.."
                                        required
                                        value={blogData.publisherGithub}
                                        name='publisherGithub'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full px-3 mb-4">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Publisher Linkedin Profile
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Linkedin Profile..."
                                        required
                                        value={blogData.publisherLinkedin}
                                        name='publisherLinkedin'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full px-3 mb-4">
                                    <label className="block uppercase tracking-wide text-[#ffb500] text-xs font-bold mb-2" for="grid-last-name">
                                        Publisher Twitter Profile
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Twitter Profile..."
                                        required
                                        value={blogData.publisherTwitter}
                                        name='publisherTwitter'
                                        onChange={handleChange}
                                    />
                                </div>
                                
                            </div>

                        </div>
                    </div>

                    <button className=' bg-[#ffb500] px-10 py-2 flex gap-2 items-center text-lg text-black rounded-md font-semibold' type='submit'>
                        <MdPublish /> Publish
                    </button>
                </form>
            </div>

        </div>
    )
}

export default AddBlog
