import React, { useEffect } from 'react'
import styles from '../components/LandingPage/style.module.css'
import Herosection from '../components/FAQ/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import Faq from '../components/ServicePage/Faq/Faq'
import OurWork from '../components/LandingPage/OurWork/OurWork'
import Quote from '../components/ServicePage/Quote/Quote'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const FaqPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Faq Page" });
        window.scroll(0, 0)
      }, [])
    return (
        <div className={styles.herosection__background}>
            <Herosection />
            <GetQuote bg />
            <OurWork />
            <Quote />
            <Faq />
        </div>
    )
}

export default FaqPage