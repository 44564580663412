import React from 'react'

const ContactLandingPage = () => {
    return (
        <div className=''>
            <div className=' h-[50vh] sm:h-[20vh] bg-[#002E5ADB]/80 flex justify-center items-center flex-col'>
                <div data-aos="fade-down" data-aos-duration='1500'>
                    <h1 className='text-5xl text-white font-bold sm:text-5xl'>Contact Us</h1>
                </div>
            </div>
        </div>
    )
}

export default ContactLandingPage