const Card = ({ img, name, review, designation, box }) => {
    return (
        <div className={`flex flex-col bg-inherit text-white mt-10 pb-8 md:pb-10 md:w-full sm:w-full sm:mt-2 ${box && 'border border-[##E48824] rounded-bl-[4rem] w-5/12 sm:w-full'}`}>
            <p className='rotate-180 text-right text-9xl leading-7 pt-12 -mt-12 font-[Catamaran] sm:text-7xl sm:pt-2'>
                "
            </p>
            <p className='text-xl mt-0 text-left  sm:text-sm'>
                {review}
            </p>
            <div className='flex gap-5 mt-10'>
                <div className='w-20 h-20 '>
                    <img src={img} alt={`profile of ${name}`} className='rounded-full border border-[#E7AF02] object-fill ' />
                </div>
                <div className='self-center'>
                    <p className='uppercase font-semibold'>
                        {name}
                    </p>
                    <p>
                        {designation}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Card