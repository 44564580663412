import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import logo from '../../Assets/logo/pack-4-movers-Logo.png'
import largeLogo from '../../Assets/logo/pack-4-movers-lg-Logo.png'

export const Navbar = () =>
{

  const navigate = useNavigate()
  const liEle = useRef()
  const burgerEle = useRef()


  return (
    // < !--Main navigation container-- >
    <nav className="sticky top-0 z-[100] backdrop-blur-xl bg-white/40 px-10 sm:px-0 lg:px-0 md:px-0">
      <div className="navbar justify-between ">
        <div className="navbar-start w-3/12 lg:w-2/3 lg:justify-between md:justify-between md:w-2/3 sm:justify-between sm:w-2/3">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost hidden lg:flex md:flex sm:flex ">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
            </label>
            <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[100] bg-white p-2 shadow  rounded-box w-52">
              <li onClick={() =>
              {
                navigate('/')
                burgerEle.current.click()
              }}><a className="hover:bg-white hover:text-[#ffb500] active:">Home</a></li>
              <li onClick={() => navigate('/about')}><a className="hover:bg-white hover:text-[#ffb500] active:">About</a></li>
              <li onClick={() => navigate('/contact')}><a className="hover:bg-white hover:text-[#ffb500] active:">Contact</a></li>
              <li onClick={() => navigate('services')}><a className="hover:bg-white hover:text-[#ffb500] active:">Services</a></li>
              <li onClick={() => navigate('team')}><a className="hover:bg-white hover:text-[#ffb500] ">Team</a></li>
              <li tabIndex={0}>
                <details  >
                  <summary >More</summary>
                  <ul className="p-2 w-max">
                    {/* <li onClick={() => navigate('/faq')}><a>FAQ</a></li> */}
                    <li onClick={() =>
                    {
                      navigate('/request-quote')
                      liEle.current.click()
                    }} ><a className="font-medium  hover:text-[#ffb500]">Request Quote</a></li>
                    <li onClick={() =>
                    {
                      navigate('blogs')
                      liEle.current.click()
                    }}><a className="font-medium  hover:text-[#ffb500]">Blogs</a></li>
                    <li onClick={() =>
                    {
                      navigate('testimonials')
                      liEle.current.click()
                    }}><a className="font-medium  hover:text-[#ffb500]">Testimonials</a></li>
                  </ul>
                </details>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-7/12 sm:w-7/12 flex justify-center">
            <a className="btn btn-ghost normal-case hover:bg-transparent self-end"
              onClick={() => navigate('/')} >
              <img src={logo} alt="packy Logo" className="h-10 hidden md:block sm:block" />
              <img src={largeLogo} alt="packy Logo" className="h-10 md:hidden sm:hidden" />
            </a>
          </div>
        </div>
        <div className="navbar-center lg:hidden md:hidden sm:hidden flex ">
          <ul className="menu menu-horizontal px-1 font-medium">
            <li onClick={() => navigate('/')}><a className="hover:bg-white hover:text-[#ffb500] active: active:bg-white">Home</a></li>
            <li onClick={() => navigate('/about')}><a className="hover:bg-white hover:text-[#ffb500] active:">About</a></li>
            <li onClick={() => navigate('/contact')}><a className="hover:bg-white hover:text-[#ffb500] active:">Contact</a></li>
            <li onClick={() => navigate('services')}><a className="hover:bg-white hover:text-[#ffb500] active:">Services</a></li>
            <li onClick={() => navigate('team')}><a className="hover:bg-white hover:text-[#ffb500] active:">Team</a></li>
            <li tabIndex={0}>
              <details>
                <summary ref={liEle} className=" font-medium hover:bg-white hover:text-[#ffb500]">More</summary>
                <ul className="p-2 w-max ">
                  {/* <li onClick={() => navigate('faq')}><a className="font-medium  hover:text-[#ffb500]">FAQ</a></li> */}
                  <li onClick={() =>
                  {
                    navigate('/request-quote')
                    liEle.current.click()
                  }} ><a className="font-medium  hover:text-[#ffb500]">Request Quote</a></li>
                  <li onClick={() =>
                  {
                    navigate('blogs')
                    liEle.current.click()
                  }}><a className="font-medium  hover:text-[#ffb500]">Blogs</a></li>
                  <li onClick={() =>
                  {
                    navigate('testimonials')
                    liEle.current.click()
                  }}><a className="font-medium  hover:text-[#ffb500]">Testimonials</a></li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
        <div className="navbar-end w-3/12 lg:w-1/3 md:w-1/3 sm:w-1/3">
          <a onClick={() => navigate('/request-quote')} className="btn sm:btn-md sm:text-xs border-none rounded-none rounded-bl-3xl bg-[#ffb500] text-black hover:text-white hover:bg-[#052D52] transition-all duration-200 ease-linear ">Get a Quote</a>
        </div>
      </div>
    </nav>
  );
};
