import React, { useState } from "react";

const faqData = [
  {
    question:
      "How can I be sure that the deportation location will provide us with enough services?",
    answer:
      "Pack4Movers, a PAN India enterprise, operates over 125 branches in major urban centers, offering a diverse fleet of modern automobiles, weatherproof packing materials, and skilled experts.",
  },
  {
    question:
      "My family would want to load our car while we are there. Is it practical?",
    answer:
      "Certainly, loading your car while you are present on moving day is practical and can be arranged. This allows you to keep important belongings or items you'll need immediate access to during the move with you in your car.",
  },
  {
    question:
      "I need a status report of my shipment on a daily basis. Can it be provided?",
    answer:
      "Certainly! At Pack4Movers, we understand the importance of staying informed about the status of your shipment during the relocation process. We offer daily status reports to keep you updated on the progress of your shipment.",
  },
  {
    question:
      "My wife would wish to install a Mandir before entering the new house at the destination. Is it possible?",
    answer:
      "We understand the significance of installing a Mandir (a place of worship) before entering your new house. At Pack4Movers, we are dedicated to accommodating your specific needs and preferences during your relocation.",
  },
  {
    question:
      "How many days of prior notice does Pack4Movers need to schedule a survey of the items to be relocated?",
    answer:
      "At Pack4Movers, we typically request a minimum of 2 to 3 days of prior notice to schedule a survey of the items you plan to relocate.",
  },
];

function Accordion() {
  const [accordion, setActiveAccordion] = useState(0);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
    } else {
      setActiveAccordion(index);
    }
  }

  return (
    <>
      <div
        className="grid w-[100%] sm:w-[80%] md:w-[70%] p-4 "
        style={{ width: "100%", gap: "10px", padding: "1rem" }}
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="150"
      >
        {faqData.map((item, index) => (
          <div
            key={index}
            onClick={() => toggleAccordion(index)}
            className="group bg-white shadow-md cursor-pointer ring-1 ring-gray-400 rounded-bl-xl"
          >
            <div
              className={`px-4 py-3 border-b border-gray-200 items-center flex justify-between p-5 font-medium cursor-pointer list-none group-open:bg-[#] group-open:p-4 ${
                accordion === index ? "bg-[#ffb500]" : ""
              }`}
            >
              <h3 className="font-semibold">{item.question}</h3>
              <div>
                <span
                  className={`transition transform ${
                    accordion === index ? "rotate-180" : ""
                  }`}
                >
                  <svg
                    fill="none"
                    height="24"
                    shapeRendering="geometricPrecision"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    width="24"
                    style={{
                      transform:
                        accordion === index ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  >
                    <path d="M6 9l6 6 6-6"></path>
                  </svg>
                </span>
              </div>
            </div>
            <div
              className={`p-4 text-neutral-600 group-open:animate-fadeIn ${
                accordion === index ? "block overflow-y-auto" : "hidden"
              }`}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Accordion;
