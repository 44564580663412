import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Navbar } from "./components/Navbar/Navbar";
import ContactUsPage from "./Pages/ContactUsPage";
import LandingPage from "./Pages/LandingPage";
import ServicesPage from "./Pages/ServicesPage";
import Footer from "./components/Footer/Footer";
import RequestQuatePage from "./Pages/RequestQuatePage";

import AboutPage from "./Pages/AboutPage";
import FaqPage from "./Pages/FaqPage";
import BlogPage from "./Pages/BlogPage";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { TestimonialPage } from "./Pages/TestimonialPage";
import Error from "./Pages/Error";
import TeamPage from "./Pages/TeamPage";
import { BackendData } from "./Pages/BackendData";
import { MarkedData } from "./components/BackendData/MarkedData";
import { UnMarkedData } from "./components/BackendData/UnMarkedData";
import FreeQuote from "./components/BackendData/FreeQuote";
import ShippingInfo from "./components/BackendData/ShippingInfo";
import { NewsLetter } from "./components/BackendData/NewsLetter";
import { Footer1 } from "./components/Footer/Footer1";
import { CustomerProtection } from "./Pages/CustomerProtection";
import Policy from "./Pages/Policy";
import AddBlogPage from "./Pages/AddBlogPage";
import BlogDetailPage from "./Pages/BlogDetailPage";
import BackendTable from "./Pages/BackendTable";
import { NewsletterTable } from "./components/BackendData/NewsletterTable";


AOS.init({
  initClassName: "initial-anim",
  once: true,
  mirror: true,
  offset: 300,
  debounceDelay: 100,
  throttleDelay: 100,
});

function App() {
  useEffect(() => {
    AOS.init({
      initClassName: "initial-anim",
      once: true,
      mirror: true,
      offset: 0,
      debounceDelay: 100,
      throttleDelay: 100,
    });
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path={"/"} element={<LandingPage />} />
        <Route path={"/services"} element={<ServicesPage />} />
        <Route path={"/contact"} element={<ContactUsPage />} />
        <Route path={"/request-quote"} element={<RequestQuatePage />} />
        <Route path={"/about"} element={<AboutPage />} />
        <Route path={"/faq"} element={<FaqPage />} />
        <Route path={"/blogs"} element={<BlogPage />} />
        <Route path={"/testimonials"} element={<TestimonialPage />} />
        <Route path={"/team"} element={<TeamPage />} />
        <Route path={"*"} element={<Error />} />
        <Route path="/customer-protection" element={<CustomerProtection />} />
        <Route path={"/policy"} element={<Policy />} />
        <Route path={"/add-blogs"} element={<AddBlogPage />} />
        <Route path={"/blogs/:id/:blogname"} element={<BlogDetailPage />} />
        <Route path={"/backend-table"} element={<BackendTable />} />
        <Route path={"/backend-newstable"} element={<NewsletterTable />} />

        {/* <Route
          path={"/backend-data/unmarked-data"}
          element={<UnMarkedData />}
        />
        <Route path={"/backend-data/marked-data"} element={<MarkedData />} />
        <Route path={"/backend-data/shipping-info"} element={<ShippingInfo />} />
        <Route path={"/backend-data/free-quote"} element={<FreeQuote />} />
        <Route path={"/backend-data/newsletter"} element={<NewsLetter/>} /> */}
      </Routes>
      {/* <Footer /> */}
      <Footer1 />

    </>
  );
}

export default App;
