import React, { useEffect } from 'react'

import styles from '../components/LandingPage/style.module.css'
import Herosection from '../components/Team/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import OurTeam from '../components/Team/OurTeam/OurTeam'
import Page3 from '../components/contact/Page3'
import Testimonial from '../components/LandingPage/Testimonial/Testimonial'
import Quote from '../components/ServicePage/Quote/Quote'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const TeamPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Team Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <div className={styles.herosection__background}>
            <Herosection />
            {/* <GetQuote bg /> */}
            <OurTeam />
            <Page3 />
            {/* <Testimonial /> */}
            <Quote />
        </div>
    )
}

export default TeamPage