import React from 'react'

const Herosection = () => {
    return (
        <section className=' bg-[#002E5ADB]/80 z-0'>
            <div className='h-[70vh] sm:h-[20vh] text-center flex justify-center items-center text-white'>
                <h1 className='text-6xl font-bold '>
                    FAQ
                </h1>

            </div>

        </section>
    )
}

export default Herosection