import React, { useEffect } from "react";
import ServicessCard from "../components/ServicePage/ServicesCard/ServicessCard";
import { service_card_data } from "../data";
import ImgBack from "../components/ServicePage/ImgBack/ImgBack";
import GetQuote from "../components/LandingPage/GetQuote/GetQuote";
import OurWork from "../components/LandingPage/OurWork/OurWork";
import Page3 from "../components/contact/Page3";
import Faq from "../components/ServicePage/Faq/Faq";
import Quote from "../components/ServicePage/Quote/Quote";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

function ServicesPage() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Services Page" });
    window.scroll(0, 0)
  }, [])
  return (
    <div
      className="flex flex-col bg-white justify-center bg-no-repeat bg-fixed bg-cover bg-center"
      style={{
        backgroundImage:
          " url('https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/services_banner.jpg')",
      }}
    >
      <ImgBack />
      {/* <GetQuote bg /> */}

      <div className="bg-white">
        <h2
          className="text-yellow-500 font-montserrat text-2xl font-semibold leading-6 p-10 tracking-normal text-center"
          data-aos="fade-down-left"
          data-aos-duration="800"
        >
          OUR SERVICES
        </h2>
        <p
          className="font-montserrat text-5xl font-semibold leading-tight text-center pb-10"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="150"
        >
          We Provide World Class Logistic Services
        </p>
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center items-stretch gap-10 p-10">
          {service_card_data.map((item) => (
            <ServicessCard item={item} key={item.id} />
          ))}
        </div>
      </div>
      <OurWork />
      <Page3 />
      <Faq />
      <Quote />
    </div>
  );
}

export default ServicesPage;
