import React from "react";

function ServicessCard(item) {
  return (
    <>
      <div
        className="group relative w-full max-w-lg m-auto flex justify-center transition-all duration-200 ease-linear items-stretch self-stretch space-x-10 space-y-10 "
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <div className=" relative w-full card h-[26rem] bg-base-100 shadow-xl border   border-[#ffb500] sm:h-auto sm:group-hover:h-[26rem]">
          <figure className="px-10 pt-10 h-[70%] aspect-w-16 aspect-h-9">
            <img
              src={item.item.img}
              alt={item.item.title}
              className="rounded-xl transform w-full h-full transition-transform duration-700 scale-100 group-hover:scale-50  group-hover:h-auto group-hover:shadow-md group-hover:absolute sm:h-auto"
            />
          </figure>
          <div className="card-body group-hover:h-auto items-center text-center mt-0 transition-transform duration-700 group-hover:mt-[10%">
            <h2 className="card-title transition-all duration-200 ease-linear">
              {item.item.title}
            </h2>
            <p className="hidden text-sm transition-all duration-200 ease-linear  group-hover:block">
              {item.item.desc}
            </p>
            <div className="card-actions">
              <button className="btn bg-[#ffb500] hover:bg-[#002E5A] hidden group-hover:block text-black hover:text-white transition-all duration-200 ease-linear ">
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicessCard;
