import React from "react";
import RequestForm from "./RequestForm";
import SpeakerPhone from "../../Assets/SVG_Image/Speaker Phone.svg";
import { BsArrowRight } from "react-icons/bs";
import Location from "../../Assets/SVG_Image/Location.svg";
import Phone from "../../Assets/SVG_Image/Phone.svg";
import Email from "../../Assets/SVG_Image/Upload Mail.svg";
import { Link } from "react-router-dom";

const RequestQuate = () => {
  return (
    <section className="bg-white">
      <div className="w-10/12 mx-auto md:w-11/12 sm:w-11/12 bg-white md:flex md:flex-col pb-10 sm:pb-6">
        <div className="flex flex-col justify-center items-center py-8">
          <h3 className="text-xl font-semibold py-2 sm:text-center text-[#666C89]">
            REQUEST QUOTE
          </h3>
          <h1 className="text-4xl font-bold sm:text-center">
            How To Send Your Packge
          </h1>
        </div>
        <div className="flex justify-center md:gap-2 sm:gap-2 sm:flex-col md:flex-col sm:py-4">
          <div className="w-8/12 mx-auto md:w-11/12 sm:w-11/12">
            <RequestForm />
          </div>
          <div className="w-4/12 mx-auto flex flex-col gap-4 py-10 px-10 md:py-10 md:px-0 sm:px-0 sm:mt-4 md:w-10/12 sm:w-11/12">
            <Link to="https://www.google.com/maps/place/Shree+prasad+vihar/@21.2172707,79.0508738,16z/data=!4m6!3m5!1s0x3bd4c393cf72c217:0xbde07f447da8a405!8m2!3d21.2171557!4d79.0563026!16s%2Fg%2F11qpjb9z13?entry=ttu" target="_blank">
              <div className="flex gap-4 md:flex-col md:items-center md:text-center md:gap-2 sm:flex-col sm:items-center sm:text-center sm:gap-2">
                <div className="w-12 pt-2 sm:pt-0">
                  <img style={{ width: "40px" }} src={Location} alt="Location logo" />
                </div>
                <div className="w-80 md:w-96 sm:w-72">
                  <h3 className="text-3xl font-semibold pb-1">Location</h3>
                  <h4 className="">
                    Ground Floor G-1 Shree prasad vihar Near BSNL Tower Godhani
                    Nagpur 441501
                  </h4>
                </div>
              </div>
            </Link>
            <Link to={'tel:+91 87677 30916'}>
              <div className="flex items-center gap-4 md:flex-col md:text-center md:gap-2 sm:flex-col sm:text-center sm:gap-2">
                <div className="w-12">
                  <img style={{ width: "40px" }} src={Phone} alt="Phone logo" />

                </div>
                <div className="">
                  <h3 className="text-3xl font-semibold pb-1">Phone</h3>
                  <span className="">
                    87677 30916
                  </span>
                </div>
              </div>
            </Link>
            <Link to={'mailto:pack4movers.ie@gmail.com'}>
              <div className="flex items-center gap-4 md:flex-col md:text-center md:gap-2 sm:flex-col sm:text-center sm:gap-2">
                <div className="w-12">
                  <img style={{ width: "40px" }} src={Email} alt="Email logo" />

                </div>
                <div className="">
                  <h3 className="text-3xl font-semibold pb-1">Email</h3>
                  <h4 className=" sm:text-center sm:w-full">pack4movers.ie@gmail.com</h4>
                </div>
              </div>
            </Link>
            <div className="mt-4 md:w-11/12 sm:w-11/12 m-auto sm:mt-2 sm:flex sm:justify-center sm:items-center">
              <div className="bg-[#052D52] p-4 rounded-bl-3xl  w-full md:flex-col md:justify-center md:items-center sm:w-full flex items-center sm:flex-col sm:justify-center">
                <div className="px-4 w-full md:flex md:flex-col md:justify-center md:items-center sm:w-full sm:flex sm:flex-col sm:justify-center sm:items-center">
                  <div className="sm:justify-center ">
                    <h3 className="text-3xl font-semibold pb-1 text-white sm:text-2xl sm:text-center">
                      How Can I Help You
                    </h3>
                  </div>
                  <div className="m-4 sm:text-center">
                    <button
                      type="submit"
                      className="bg-[#ffb500] hover:bg-white hover:text-[#ffb500] p-4 rounded-bl-2xl font-bold"
                    >
                      Contact Us
                    </button>
                  </div>
                </div>
                <div className=" flex justify-center pr-4 sm:hidden">
                  <BsArrowRight size={40} color="white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequestQuate;
