import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { supabase } from "../../../Supabase/Config";
import { Link, useNavigate } from "react-router-dom";

function Quote() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    moveFrom: "",
    moveTo: "",
    phoneNumber: "",
    emailId: "",
    date: "",
    time: "",
    message: "",
  });

  const [alert, setAlert] = useState("");
  const [message, setMessage] = useState("");
  const [hide, setHide] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    console.log("submit");
    try {
      const { error } = await supabase
        .from("Get_A_Free_Quote")
        .insert({ ...formData });

      if (error) {
        setAlert("error");
        setMessage("Error! Your Request is failed");
        setHide(true);
      } else {
        setAlert("success");
        setMessage("Your Request was successfully");
        setHide(true);
        setFormData({
          moveFrom: "",
          moveTo: "",
          phoneNumber: "",
          emailId: "",
          date: "",
          time: "",
          message: "",
        });
      }
    } catch (error) {
      setAlert("error");
      setHide(true);
      setMessage(error.message);
    }

    setTimeout(() => {
      setHide(false);
    }, 2000);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-white  pt-1 pb-[15rem] lg:pb-[23rem] sm:pb-[28rem] xs:pb-[33rem] md:pb-[21rem]">
      <div className="bg-[#002E5A] md:min-h-[33vh] sm:min-h-[50vh] rounded-bl-3xl mx-10 sm:mx-4  mt-16 md:mt-10 sm:mt-10">
        <div className="container mx-auto sm:flex-col md:block flex gap-8">
          <div className="w-3/4 sm:w-[100%] xs:w-[100%] md:w-[100%] p-4 sm:m-auto  md:m-auto">
            <div className="max-w-screen-xl mx-auto px-5 md:mt-0 relative sm:static md:static w-[100%]  min-h-sceen">
              <div className="flex flex-col">
                <h2
                  className="font-bold text-5xl mt-5 text-white tracking-tight"
                  data-aos="fade-down-left"
                  data-aos-duration="800"
                >
                  Get A Free Quote
                </h2>
              </div>
              <div
                className="w-[100%] sm:w-[92%] xs:w-[92%] md:w-[70%] p- divide-y md:left-1/2 md:!-translate-x-[50%] sm:left-1/2 sm:!-translate-x-[50%] divide-neutral-200 mx-auto mt-8 shadow-xl bg-white absolute rounded-bl-3xl"
                style={{
                  gap: "10px",
                }}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="150"
              >
                <div className="flex justify-center ">
                  <div className="p-4 w-full bg-white">
                    <form onSubmit={handleSubmit}>
                      <div className="grid gap-6 mb-6 grid-cols-2 sm:grid-cols-1">
                        <div>
                          <input
                            type="text"
                            name="moveFrom"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Move From"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            name="moveTo"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Move To"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div>
                          <div className="relative">
                            <select
                              className="block appearance-none w-full bg-[#F9FAFB] border border-gray-200 focus:ring-orange-500 focus:border-orange-500 text-gray-700 py-4 px-4 pr-8 leading-tight focus:bg-white"
                              id="grid-state"
                            >
                              <option selected>Move From</option>
                              <option value="Nagpur">Nagpur</option>
                              <option value="Chandrapur">Chandrapur</option>
                              <option value="Tiorda">Tiorda</option>
                              <option value="Ahmedabad">Ahmedabad</option>
                              <option value="Gandhidham">Gandhidham</option>
                              <option value="Raipur">Raipur</option>
                              <option value="Vadodara">Vadodara</option>
                              <option value="Surat">Surat</option>
                              <option value="Rajkot">Rajkot</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="grid gap-6 mb-6 grid-cols-2 sm:grid-cols-1">
                        <div>
                          <input
                            type="tel"
                            id="phone"
                            name="phoneNumber"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Phone Number"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="">
                          <input
                            type="email"
                            id="email"
                            name="emailId"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Email"
                            required
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="grid gap-6 mb-6 grid-cols-2 sm:grid-cols-1">
                        <div>
                          <input
                            type="date"
                            id="date"
                            name="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Date"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <input
                            type="time"
                            name="time"
                            id="email"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-4 "
                            placeholder="Time"
                            required
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mb-6 sm:mb-2">
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          type="text"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50  border border-gray-300 focus:ring-orange-500 focus:border-orange-500 "
                          placeholder="Message"
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="text-black hover:text-white bg-[#ffb500] hover:bg-[#002E5A] focus:ring-4 text-md w-1/2 sm:w-auto text-center  p-4 transition-all ease-in duration-200 rounded-bl-2xl font-bold"
                      >
                        Get A Quote
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="lg:w-2/3 sm:hidden md:hidden lg:relative"
              data-aos="fade-up"
            >
              <img
                src="https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/get_quote_man_img.png"
                alt="get_quote_man_img"
                style={{ marginTop: "-10%" }}
              />
            </div>

            <div className="mx-2  sm:hidden md:hidden"
            // data-aos="fade-up"
            >
              <div className="bg-[#002E5A] sm:mt-60 sm:w-[88%] md:w-[80%] p-4 rounded-bl-3xl w-[35%] flex items-center sm:flex-col sm:justify-center absolute mt-8" onClick={() => navigate("/request-quote")}>
                <div className="px-4 sm:justify-center ">
                  <div className="sm:justify-center ">
                    <h3 className="text-3xl font-semibold pb-1 text-white sm:text-center">
                      How Can I Help You
                    </h3>
                  </div>
                </div>
                <div className=" flex justify-center pr-4">
                  <Link to={"/request-quote"}>
                    <BsArrowRight size={40} color="white" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quote;
