import React, { useEffect, useState } from 'react'

export const Pagination = ({pageNum, setPageNum, paginationValue, noOfPages}) => {

    const handlePrevPage=()=>{
       if(pageNum >= 1) setPageNum((prev)=> prev - 1)
    }
    const handleNextPage=()=>{
        if(noOfPages >= pageNum) setPageNum((prev)=> prev + 1)
    }
    
  return (
    <div className='w-11/12 m-auto'>
        <span>
            <h4 className=' text-center font-bold mb-1'>{pageNum} Of {noOfPages}</h4>
        </span>
        <div className='flex justify-center gap-2 mb-16'>
            <button className={`px-4 py-1 text-white  bg-purple-900 rounded-md ${pageNum==1 && " opacity-80 cursor-not-allowed"}`} 
            disabled={pageNum===1} onClick={handlePrevPage}>Prev</button>
            <button className={`px-4 py-1 text-white  bg-purple-900 rounded-md ${pageNum==noOfPages && " opacity-80 cursor-not-allowed"}`}
            disabled={pageNum===noOfPages} onClick={handleNextPage}>Next</button>

        </div>
    </div>
  )
}
