import React from 'react'

import { AiOutlineArrowRight } from 'react-icons/ai'


const Card = ({ img, title, date, link }) => {
    return (
        <div className="w-[30%] my-auto  bg-white rounded-bl-3xl border border-[#ffb500] shadow-md sm:w-full sm:my-4">
            <div className="">
                <div className=" relative  flex">
                    <div className='overflow-hidden w-full'>

                        <img className="w-full hover:scale-105 object-cover transition-all duration-150 ease-in hover:rotate-2 hover:object-contain z-10 " src={img} alt={title} />
                    </div>
                    <div className='text-white text-center bg-[#ffb500] absolute z-10 px-4 -translate-x-2 py-2 self-end md:px-2 md:py-1'>
                        <p>
                            {date?.split(' ')[1]}
                        </p>
                        <p>
                            {date?.split(' ')[0].split('')[0]}
                            {date?.split(' ')[0].split('')[1]}
                            {date?.split(' ')[0].split('')[2]}
                        </p>
                    </div>
                </div>
                <div className='flex justify-between items-center w-11/12 m-auto'>

                    <p className="mt-2 text-gray-500">By admin</p>
                    <p className="block mt-1 text-lg leading-tight font-medium text-black ">{date}</p>
                </div>
                <div className="p-2">
                    <div className="mt-4 h-max">
                        <div className="uppercase text-sm tracking-wide font-semibold sm:text-xl xs:text-base">{title}
                        </div>
                        <div className='flex gap-2 items-center w-11/12 m-auto py-5 text-[#ffb500]'>
                            <a href="#" className=" font-semibold">
                                Read More
                            </a>
                            <AiOutlineArrowRight />

                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Card