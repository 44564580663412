import React, { useState, useEffect } from 'react'
import { supabase } from '../Supabase/Config'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Pagination } from '../components/BackendData/Pagination'
import { Oval } from 'react-loader-spinner'

const paginationValue = 10;

const BackendTable = () => {
    const [backedData, setBackendData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [category, setCategory] = useState('')
    const [filterData, setFilterData] = useState([])
    const [cardId, setCardId] = useState(Number)
    const [modal, setModal] = useState(false)
    const [open, setOpen] = useState(false)
    const [movingDataId, setMovingDataId] = useState(null)
    const [pageNum, setPageNum] = useState(1)

    const [totalData, setTotalData] = useState([])
    const noOfPages= Math.ceil(totalData.length/paginationValue)

    const getTotalFormData = async () => {
      try {
        const { data, error } = await supabase
          .from('Get_Quote_Form_Data')
          .select('*')
          .order('id', { ascending: false })
        if (data) {
          setTotalData(data)
        }

      } catch (error) {
        console.log(error)
      }
    }
    useEffect(() => {
      getTotalFormData();
    }, [])



    const getData = async () => {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('Get_Quote_Form_Data')
                .select('*')
                .order('id', { ascending: false })
                .range(paginationValue * pageNum - paginationValue, paginationValue * pageNum - 1)
            if (data) {
                setBackendData(data)
                setIsLoading(false)
            }
            if (error) {
                setIsLoading(false)
                console.log("ERRR", error)
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getData();
    }, [pageNum])

    const handleFilterCategory = () => {
        // console.log("FILER FUNCTION CALLLLLLLLLLLLL......", backedData)
        let newFilterData = []
        backedData.filter((el) => {
            if (el.category === category) {
                newFilterData.push(el)
            }
        })
        setFilterData(newFilterData)
    }

    useEffect(() => {
        handleFilterCategory()
    }, [category])


    const handleAddTaskComplete = async (num) => {

        let newOne = backedData.filter((ele) => ele.id === num)
        // console.log(newOne)
        try {

            const { error } = await supabase
                .from('Get_Quote_Form_Data')
                .delete()
                .eq('id', num)

            if (error) {
                console.log(error)
                // filterData.filter((ele) => ele.id !== num)
                // getData()
            } else {
                filterData.filter((ele) => ele.id !== num)
                getData()
                // console.log('successssssssssss............')
            }
        } catch (error) {
            console.log(error)
        }
        try {

            const { error } = await supabase
                .from('Get_Quote_Form_Data_Checked')
                .insert(...newOne)
            if (error) {
                console.log(error)
            } else {
                // console.log('successssssssssss............' + "data inserted successfully...")
            }
        } catch (error) {
            console.log(error)
        }
    }


    // console.log(backedData)
    // id, category, fullName, emailId, mobileNumber, fromPickUp, toDeliver,
    return (
        <div>
            {
                isLoading ?
                    (<div className='flex justify-center items-center h-[40vh]'>
                        <Oval
                            height={80}
                            width={80}
                            color="#ffb500"
                            wrapperStyle={{}}
                            wrapperclassName=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffb500"
                            strokeWidth={5}
                            strokeWidthSecondary={2}

                        />
                    </div>)
                    :
                    <>
                        {
                            backedData.length > 0
                                ?
                                <>
                                    <div className='w-11/12 m-auto'>

                                        <div className=" py-8 w-full">
                                            <div className="shadow md:overflow-x-scroll sm:overflow-x-scroll rounded border-b border-gray-200">
                                                <table className="min-w-full bg-white">
                                                    <thead className="bg-gray-800 text-white">
                                                        <tr >
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Sr. No.</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Id</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Full Name</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Email Id</th>
                                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Category</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Mobile No</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Pick Up Address</th>
                                                            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Deliver Address</th>
                                                            <th className="w-32 text-left py-3 px-4 uppercase font-semibold text-sm">Date</th>
                                                            <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-gray-700">
                                                        {
                                                            backedData.length && backedData?.map((el, i) => (
                                                                <tr className={`border-b text-sm text-black ${i % 2 == 0 ? "bg-slate-200" : ""}`}>
                                                                    <td className=" text-left py-3 px-4">{i + 1 + paginationValue * pageNum - paginationValue}</td>
                                                                    <td className=" text-left py-3 px-4">{el.id}</td>
                                                                    <td className=" text-left py-3 px-4">{el.fullName}</td>
                                                                    <td className=" text-left py-3 px-4">{el.emailId}</td>
                                                                    <td className=" text-left py-3 px-4">{el.category}</td>
                                                                    <td className=" text-left py-3 px-4">{el.mobileNumber}</td>
                                                                    <td className=" text-left py-3 px-4">{el.fromPickUp}</td>
                                                                    <td className="text-left py-3 px-4">{el.toDeliver}</td>
                                                                    <td className=" text-left py-3 px-4">{new Date(el.created_at.split(" ")[0]).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                                                                    <td className=" text-left py-3 px-4">
                                                                        <button className="text-sm bg-orange-500 hover:bg-orange-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                                            onClick={() => {
                                                                                setOpen(!open)
                                                                                setMovingDataId(el.id)
                                                                            }}>Complete</button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div >
                                    <Pagination pageNum={pageNum} setPageNum={setPageNum} paginationValue={paginationValue} />
                                </>
                                :
                                <div className='h-[40vh] flex items-center justify-center'>
                                    <h4 className=' text-3xl font-semibold text-center'>No Data</h4>
                                </div>
                        }
                    </>
            }
            <div ><CompletedModal open={open} setOpen={setOpen} movingDataId={movingDataId} handleAddTaskComplete={handleAddTaskComplete} noOfPages={noOfPages} /></div>

        </div >
    )
}

export default BackendTable



function CompletedModal({ open, setOpen, movingDataId, handleAddTaskComplete }) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed  inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed  inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full w-[33rem] sm:w-11/12 m-auto items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                                    <div className="flex gap-4 text-left">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Move to Mark Data
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to mark as complete? All of your data will be permanently
                                                    moved to mark data. This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 flex justify-end gap-4 sm:px-6">
                                    <button
                                        type="button"
                                        className=" inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center items-center rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => {
                                            setOpen(false)
                                            handleAddTaskComplete(movingDataId)
                                        }}
                                    >
                                        Move
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}