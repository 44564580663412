import React, { useEffect } from 'react'

import styles from '../components/LandingPage/style.module.css'
import Herosection from '../components/LandingPage/HeroSection/Herosection'
import OurWork from '../components/LandingPage/OurWork/OurWork'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import AboutUs from '../components/LandingPage/AboutUs/AboutUs'
import OurServices from '../components/LandingPage/OurServices/OurServices'
import OurProjects from '../components/LandingPage/OurProjects/OurProjects'
import OurProcess from '../components/LandingPage/OurProcess/OurProcess'
import Testimonial from '../components/LandingPage/Testimonial/Testimonial'
import Team from '../components/LandingPage/Team/Team'
import LatestNews from '../components/LandingPage/LatestNews/LatestNews'
import Quote from '../components/ServicePage/Quote/Quote'
import Page3 from '../components/contact/Page3'
import Faq from '../components/ServicePage/Faq/Faq'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const LandingPage = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page" });
    window.scroll(0, 0)
  }, [])

  return (
    <div className={styles.herosection__background}>
      <Herosection />
      <GetQuote bg />
      <OurServices />
      <AboutUs />
      <OurWork />
      <Quote />
      <OurProjects />
      <OurProcess />
      <Page3 />
      <Team />
      <Testimonial />
      <Faq />
      <LatestNews />
    </div>
  )
}

export default LandingPage