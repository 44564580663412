export const service_card_data = [
    {
      id: 1,
      img: 'https://www.synglegroup.com/extra-images/project-grid-img1.jpg',
      title: 'Domestic Relocation',
      desc: 'Pack4Movers has innovatively crafted convenient and efficient methods for relocating both residential and commercial properties, as well as transporting vehicles to destinations across the region.',
      logo: 'logo_url_1.png',
    },
    {
      id: 2,
      img: 'https://www.synglegroup.com/extra-images/project-grid-img2.jpg',
      title: 'Corporate Relocation',
      desc: 'Tailored corporate moving solutions to cater to the unique needs of each of our clientele.',
      logo: 'logo_url_2.png',
    },
    {
      id: 3,
      img: 'https://www.synglegroup.com/extra-images/project-grid-img3.jpg',
      title: 'International Relocation',
      desc: "Relocating overseas with Pack4Movers International Relocation stands out due to its proficiency and exceptional customer support, ensuring a distinctive experience.",
      logo: 'logo_url_3.png',
    },
    {
        id: 4,
        img: 'https://www.synglegroup.com/extra-images/car.jpg',
        title: 'Car Carrier Service',
        desc: ' vehicle Transport We have consistently delivered dependable vehicle transportation solutions to our valued customers.',
        logo: 'logo_url_1.png',
      },
      {
        id: 5,
        img: 'https://www.synglegroup.com/extra-images/packing.jpg',
        title: 'Packaging Unpackaging And Rearranging',
        desc: 'Our company provides a comprehensive range of unboxing and reorganizing solutions to simplify the process of relocation for our clients.',
        logo: 'logo_url_2.png',
      },
      {
        id: 6,
        img: '	https://www.synglegroup.com/extra-images/exim.jpg',
        title: 'Cargo Packaging',
        desc: 'Our company provides a comprehensive range of unboxing and reorganizing solutions to simplify the process of relocation for our clients.',
        logo: 'logo_url_3.png',
      },
      {
        id: 7,
        img: 'https://www.synglegroup.com/extra-images/commertial.jpg',
        title: 'Industrial/ Commercial Goods Transportation',
        desc: 'We adhere to a structured and methodical approach when carrying out all our operations, encompassing a wide array of tasks in the realm of relocation services, including packing, moving, loading, and logistics management.',
        logo: 'logo_url_1.png',
      },
      {
        id: 8,
        img: 'https://www.synglegroup.com/extra-images/odc.jpg',
        title: 'ODC Transportation',
        desc: 'Our company has consistently strived to enhance technology, swiftness, excellence, cost-effectiveness, and overall reliability.',
        logo: 'logo_url_2.png',
      },
      {
        id: 9,
        img: 'https://www.synglegroup.com/extra-images/warehouse.jpg',
        title: 'Warehouses',
        desc: "Pack4Movers encompasses the orchestration of goods and information flow within storage facilities and distribution hubs, covering every aspect of the logistics process.",
        logo: 'logo_url_3.png',
      },
  
  ];