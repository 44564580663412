import React, { useEffect } from 'react'
import ContactLandingPage from '../components/contact/ContactLandingPage'
import Page1 from '../components/contact/page1'
import Page3 from '../components/contact/Page3'

import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import Quote from '../components/ServicePage/Quote/Quote'
// import { Helmet } from "react-helmet";

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);


const ContactUsPage = () =>
{
    useEffect(() =>
    {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact Page" });
        window.scroll(0, 0)
    }, [])
    return (

        <>
            {/* <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Page</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
            </div> */}
            <div className='bg-[url(https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/contact_us_banner.jpg)] bg-cover bg-no-repeat bg-center overflow-x-hidden' style={{ backgroundAttachment: 'fixed' }}>
                <ContactLandingPage />
                <GetQuote bg />
                <Page1 />
                <Quote />
                <Page3 />
            </div>
        </>
    )
}

export default ContactUsPage