import React from "react";
import { HiOutlineLightBulb } from "react-icons/hi";
import LightOn from "../../../Assets/SVG_Image/Light On.svg";
// import { FaPlay } from 'react-icons/fa'

const AboutUsVideo = () => {
    return (
        <>
            <div className=" m-auto flex items-center justify-center bg-white">
                <div className="w-10/12 md:w-10/12 sm:w-11/12 flex items-center m-6 gap-10 sm:m-2 md:mb-0 sx:m-0 md:flex-col sm:flex-col sx:flex-col">
                    <div className="m-4 w-1/2 sm:w-full md:w-full md:mb-0 sm:mb-0 sx:mb-0">
                        <div className="pb-6">
                            <h2 className="text-[#E6AF01] text-4xl text-extrabold ">
                                About Us
                            </h2>
                        </div>
                        <div className="pb-6">
                            <h1 className="text-5xl font-bold pb-4">
                                Your Trusted Partner in Stress-Free Relocations
                            </h1>
                            <h4>
                                At Pack4Movers, we understand that moving can be a challenging
                                and stressful experience. That's why we are here to make your
                                move as seamless and hassle-free as possible.
                            </h4>
                        </div>

                        <div className="px-6 py-2 my-2 bg-white  border rounded-bl-3xl border-[#E6AF01]  shadow flex justify-center items-center sm:px-10">
                            <div className=" flex justify-center  items-center">
                                <div className='w-[14%] md:w-[10%] p-2'>
                                    <img src={LightOn} alt="Light On Logo" className='' />
                                </div>
                                <div className='w-[86%] md:w-[90%]'>
                                    <h2 className="text-3xl font-bold sm:text-2xl xs:text-xl"> Our Vision </h2>
                                    <h4 className='sm:text-sm xs:text-sm'>
                                        Our vision at Pack4Movers is to be the premier provider of
                                        moving and relocation solutions, known for our unwavering
                                        dedication to quality, reliability, and innovation.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-2 my-2 bg-white  border rounded-bl-3xl border-[#E6AF01]  shadow flex justify-center items-center ">
                            <div className=" flex justify-center  items-center">
                                <div className='w-[14%] md:w-[10%] p-2'>
                                    <img src={LightOn} alt="Light On Logo" className='' />
                                </div>
                                <div className='w-[86%] md:w-[90%]'>
                                    <h2 className="text-3xl font-bold sm:text-2xl xs:text-xl"> Our Mission </h2>
                                    <h4 className='sm:text-sm xs:text-sm'>
                                        Our goal is to deliver superior moving and packing services
                                        that go above and above for our clients. We are dedicated to
                                        protecting your possessions and moving them carefully and
                                        quickly to your new location. We take pleasure in our
                                        meticulousness and commitment to client satisfaction.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-2 my-2 bg-white  border rounded-bl-3xl border-[#E6AF01]  shadow flex justify-center items-center ">
                            <div className=" flex justify-center  items-center">
                                <div className='w-[14%] md:w-[10%] p-2'>
                                    <img src={LightOn} alt="Light On Logo" className='' />
                                </div>
                                <div className='w-[86%] md:w-[90%]'>
                                    <h2 className="text-3xl font-bold sm:text-2xl xs:text-xl"> Our Promise </h2>
                                    <h4 className='sm:text-sm xs:text-sm'>
                                        When you choose Pack4Movers, you're choosing a partner who
                                        cares about your move as much as you do. We treat your
                                        belongings as if they were our own, and we go the extra mile
                                        to ensure your peace of mind during this important
                                        transition.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex w-1/2 justify-center items-center md:items-start sm:items-start md:w-screen sm:w-screen sx:items-start  md:h-full sm:h-full md:py-0 p-4 md-p-0">
                        <video
                            className="rounded-bl-3xl w-full h-3/4 md:h-auto sm:h-auto sx:h-auto"
                            src={
                                "https://vod-progressive.akamaized.net/exp=1696512530~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F1621%2F21%2F533106238%2F2516237289.mp4~hmac=a259590ff2e3ff399721bab81f9d6bec576cdbe09bec224da2030bc9393f8a89/vimeo-prod-skyfire-std-us/01/1621/21/533106238/2516237289.mp4"
                            }
                            controls="controls"
                            autoplay="true"
                        />
                        {/* <img className='rounded-bl-3xl w-full' src="https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/about_section_img.jpg" alt="" /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUsVideo;
