import React from 'react'
import Location from '../../Assets/SVG_Image/Location.svg'
import Phone from '../../Assets/SVG_Image/Phone.svg'
import Email from '../../Assets/SVG_Image/Upload Mail.svg'
import Van from '../../Assets/Images/contactpage/van-p4m.png'
import { Link } from 'react-router-dom'

const Page1 = () => {
    return (
        <>
            <div className='bg-[#f8f8f8] mt-0 mb-0 py-6'>
                <div className='m-12 h-full mt-0 mb-0  flex justify-center items-center'>
                    <div className='flex justify-center items-center md:flex-col sm:flex-col xs:flex-col'>
                        <div className='w-2/5 md:w-screen  sm:w-screen xs:w-screen ' >
                            <Link href="https://www.google.com/maps/place/Shree+prasad+vihar/@21.2172707,79.0508738,16z/data=!4m6!3m5!1s0x3bd4c393cf72c217:0xbde07f447da8a405!8m2!3d21.2171557!4d79.0563026!16s%2Fg%2F11qpjb9z13?entry=ttu" >
                                <div className='bg-white border border-[#ffb500] rounded-bl-2xl p-4 flex w-90 h-auto sm:flex sm:flex-col sm:justify-center sm:items-center m-4 md:justify-center' data-aos='fade-right'
                                    data-aos-duration='400'>
                                    <div className='justify-center p-2'>
                                        <img width="50" height="50" src={Location} alt="settings--v1" />
                                    </div>
                                    <div className='sm:flex sm:flex-col sm:justify-center sm:items-center'>
                                        <h2 className='text-2xl font-semibold sm:text-xl'>Location</h2>
                                        <h4 className='text-lg sm:text-center'>Ground Floor G-1 Shree prasad Vihar Near BSNL Tower Godhani  Nagpur 441501</h4>
                                    </div>
                                </div>
                            </Link>
                            <Link to={'tel:+91 87677 30916'}>
                                <div className='bg-white border border-[#ffb500] rounded-bl-2xl p-4  flex w-90 h-auto sm:flex sm:flex-col sm:items-center m-4 md:justify-center sm:justify-center' data-aos='fade-left'
                                    data-aos-duration='500'>
                                    <div className='justify-center p-2'>
                                        <img width="30" height="50" src={Phone} alt="phone-logo" />
                                    </div>
                                    <div className='sm:flex sm:flex-col sm:justify-center sm:items-center'>
                                        <h3 className='text-2xl font-semibold sm:text-xl'>Phone</h3>
                                        <h4 className='text-lg  sm:text-center'>+91 8767730916</h4>
                                    </div>
                                </div>
                            </Link>
                            <Link to={'mailto:pack4movers.ie@gmail.com'}>
                                <div className='bg-white border border-[#ffb500] rounded-bl-2xl p-4 flex w-90 h-auto sm:flex sm:flex-col sm:items-center m-4 md:justify-center sm:justify-center' data-aos='fade-right'
                                    data-aos-duration='600'>
                                    <div className='justify-center p-2'>
                                        <img width="30" height="50" src={Email} alt="email-Logo" />
                                    </div>
                                    <div className='sm:flex sm:flex-col sm:justify-center sm:items-center'>
                                        <h3 className='text-2xl font-semibold sm:text-xl'>Email</h3>
                                        <h4 className='text-lg  sm:text-center'>pack4movers.com</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='w-2/4 md:hidden sm:hidden xs:hidden  ' data-aos="fade-left" data-aos-duration='1000'>
                            <img src={Van} style={{ width: '100%' }} alt="Van-img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page1