import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import required modules
import { Autoplay } from 'swiper/modules';

import swiperCardImg1 from '../../../Assets/Images/landingpage/close-up-woman-receiving-pack1.png'
import swiperCardImg2 from '../../../Assets/Images/landingpage/project_img_07.jpg'
import styles from '../style.module.css'


const swiperArray = [swiperCardImg1, swiperCardImg2]



const OurProjects = () =>
{
    return (
        <div className={` relative overflow-x-auto`}>
            <div style={{
                background: 'linear-gradient(to top,white 20%,rgb(0 46 90 / 0.8) 20% 40%'
            }} className=' pt-16  flex justify-between md:pt-0 sm:flex-col sm:pt-10' >
                <div className='w-6/12 mt-10 mb-auto mx-auto lg:mt-0 md:w-7/12 md:m-auto sm:w-11/12 sm:m-auto text-white '>
                    <h3 className='text-2xl uppercase font-medium md:text-lg sm:text-base'
                        data-aos='zoom-in'
                        data-aos-duration='800'
                    >
                        Our Projects
                    </h3>
                    <h4 className='text-5xl font-semibold mt-6 md:text-2xl md:mt-0 sm:text-xl '
                        data-aos='fade-up'
                        data-aos-duration='800'
                    >
                        You Move On, While We Move Out!
                    </h4>
                    <p className='my-6 md:my-0 md:text-sm sm:text-xs '
                        data-aos='fade-left'
                        data-aos-duration='800'
                    >
                        We live by this principle by managing all the intricacies of your relocation, allowing you to concentrate on smoothly transitioning to your new environment. Our team of skilled relocation specialists oversees every facet of your move with meticulous attention and diligence.
                    </p>
                    <div className='py-5 md:my-3 '
                        data-aos='fade-left'
                        data-aos-duration='800'
                    >
                        <a className='p-4 bg-[#ffb500] text-[#000] hover:bg-white hover:text-black transition-all ease-in duration-200 font-medium rounded-bl-2xl cursor-pointer'>
                            Click Here
                        </a>
                    </div>
                </div>

                {/* Swiper js Start */}

                <div className='w-5/12 relative self-end right-0 md:w-4/12 md:mt-16 sm:w-1/2 '
                    data-aos='zoom-in'
                    data-aos-duration='800'
                >
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className="mySwiper rounded-bl-[4rem]"
                    >
                        {
                            swiperArray?.map((ele, i) => <SwiperSlide key={i} >
                                <img src={ele} alt={`swiper card image ${i}`} />
                            </SwiperSlide>)
                        }

                    </Swiper>

                </div>

                {/* Swiper js end */}

            </div>

        </div>
    )
}

export default OurProjects