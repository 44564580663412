import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

import profile from '../../../Assets/People/Profile/Review1.jpg'
import profile2 from '../../../Assets/People/Profile/customer1.jpg'
import profile3 from '../../../Assets/People/Profile/customer2.jpg'
import Card from './Card/Card'

const swiperArray = [
    {
        img: profile,
        name: "Swapnil Mehta",
        designation: "Entrepreneur",
        review: " Recently used services from Pack4Movers for our office relocation, And I’m extremely pleased with the exceptional service they provided. The team of professional movers exhibited a high level of expertise, ensuring a smooth and efficient transition. From the initial planning stages to the actual move, Pack4Movers demonstrated a commitment to excellence. The movers were punctual, organized, and handled our office belongings with great care."
    },
    {
        img: profile2,
        name: "Adharsh Chauhan",
        designation: "Engineer",
        review: "I recently used Pack4 Movers for my home relocation, and I couldn't be happier with the service they provided. From the moment I contacted them for a quote to the final delivery of my belongings, the entire process was seamless and stress-free.The packing team was incredibly efficient and handled my fragile items with utmost care.They even provided me with all the necessary packing materials, which saved me a lot of time and effort."
    },
    {
        img: profile3,
        name: "Ritika Saini",
        designation: "Doctor",
        review: "We were super happy with our move from scheduling to completion. I found the office staff to be very professional and well qualified and extremely willing to help with questions.  I felt informed and confident that my move was going to go well.  My movers were polite and professional!  They were both very nice guys and were fast and efficient, but also attentive to every detail. "
    },

]


const Testimonial = () => {
    return (
        <section className='relative bg-white overflow-x-hidden'>
            <div className='w-11/12 m-auto flex md:flex-col-reverse sm:flex-col-reverse'>
                <div className='flex justify-end items-center'>
                    <div className='relative my-10 w-[29rem] translate-x-1/4 lg:translate-x-20 lg:w-96 md:hidden sm:hidden'>
                        {/* Swiper For Profile Image */}
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{
                                delay: 2600,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            className="mySwiper rounded-bl-[4rem]"
                        >
                            {
                                swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                                    <img src={ele.img} alt={`profile of ${ele.name}`} className='' />
                                </SwiperSlide>)
                            }
                        </Swiper>
                        {/* Swiper For Profile Image End */}
                    </div>

                </div>
                <div className='w-2/3 bg-[#002E5A] rounded-bl-[4rem] py-4 px-6 lg:w-full md:w-full sm:w-full overflow-x-hidden sm:py-8'>
                    <div className='w-10/12 ml-auto md:mx-auto sm:w-full'>

                        <h2 className='uppercase text-[#ffb500] font-semibold text-2xl'
                            data-aos='fade-left'
                            data-aos-duration='1000'
                        >
                            Testimonial
                        </h2>
                        <h4 className='text-white font-semibold text-5xl mt- sm:text-3xl'>
                            Our Happy Customer
                        </h4>
                        <div className='bg-inherit'
                        // data-aos='zoom-in'
                        // data-aos-duration='1500'
                        >
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                loop={true}
                                pagination={{ clickable: false }}
                                autoplay={{
                                    delay: 2600,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination]}
                                className="mySwiper bg-inherit"
                            >
                                {
                                    swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                                        <Card {...ele} />
                                    </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>

            </div>
        </section >
    )
}

export default Testimonial


