import React, { useEffect } from 'react'

import styles from '../components/LandingPage/style.module.css'
import Herosection from '../components/Blogs/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
// import LatestNews from '../components/Blogs/LatestNews/LatestNews'
import Testimonial from '../components/LandingPage/Testimonial/Testimonial'
import Team from '../components/LandingPage/Team/Team'
import Page3 from '../components/contact/Page3'
import Faq from '../components/ServicePage/Faq/Faq'
import LatestNews from '../components/LandingPage/LatestNews/LatestNews'
import ReactGA from "react-ga4";
import { BlogCards } from '../components/Blogs/BlogCards/BlogCards'

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const BlogPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blog Page" });
        window.scroll(0, 0)
      }, [])


    return (
        <div className={styles.herosection__background}>
            <Herosection />
            <GetQuote bg />
            <LatestNews />
            <Testimonial />
            {/* <LatestNews/> */}
            {/* <Team /> */}
            {/* <BlogCards/> */}
            <Page3 />
            <Faq />

        </div>
    )
}

export default BlogPage