import React from 'react'

// import { PiFacebookLogo } from 'react-icons/pi'
import { FaTwitter, FaFacebookF, FaInstagram } from 'react-icons/fa'
import { BiLogoGooglePlus } from 'react-icons/bi'


const Member = ({ img, name, designation, instaLink, fbLink, twitterLink, googleLink }) => {
    return (
        <div className='flex flex-col w-[30%] lg:w-96 md:w-[20rem]  bg-[#002E5A] rounded-bl-[4rem] border border-[#ffb500] sm:w-5/12 sm:my-4 transition-all duration-200 ease-in shadow-2xl sm:rounded-bl-[2rem]'>
            <div >
                <img src={img} alt={name} className='w-[25rem] h-[25rem] sm:h-44 m-auto object-cover' />
            </div>
            <div className='my-4 pb-4 self-center text-white px-1'>
                <p className='text-[1.5rem] font-semibold lg:text-2xl md:text-2xl sm:text-base'>
                    {name}
                </p>
                <p className='text-lg lg:text-lg md:text-base sm:text-sm'>
                    {designation}
                </p>
                <div className='flex justify-center items-baseline gap-2 my-4 sm:hidden'>
                    <a href={fbLink} rel="noopener noreferrer" className='bg-[#ffb500] text-black hover:bg-white transition-all duration-200 ease-in relative hover:-translate-y-3 p-2 rounded-full border border-[#ffb500]' >
                        <FaFacebookF className='text-2xl md:text-base sm:text-lg' />
                    </a>
                    <a href={twitterLink} rel="noopener noreferrer" className='bg-[#ffb500] text-black hover:bg-white transition-all duration-200 ease-in relative hover:-translate-y-3 p-2 rounded-full border border-[#ffb500]' >
                        <FaTwitter className='text-2xl md:text-lg sm:text-base' />
                    </a>
                    <a href={googleLink} rel="noopener noreferrer" className='bg-[#ffb500] text-black hover:bg-white transition-all duration-200 ease-in relative hover:-translate-y-3 p-2 rounded-full border border-[#ffb500]' >
                        <BiLogoGooglePlus className='text-2xl md:text-base sm:text-lg' />
                    </a>
                    <a href={instaLink} rel="noopener noreferrer" className='bg-[#ffb500] text-black hover:bg-white transition-all duration-200 ease-in relative hover:-translate-y-3 p-2 rounded-full border border-[#ffb500]' >
                        <FaInstagram className='text-2xl md:text-base sm:text-lg' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Member