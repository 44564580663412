import { useState } from 'react';
import { supabase } from '../../Supabase/Config'

const RequestForm = () =>
{

    const moveFromData = [
        { value: '', text: 'Move Form' },
        { value: 'Item1', text: 'Item1' },
        { value: 'Item2', text: 'Item2' },
        { value: 'Item3', text: 'Item3' },
    ];
    const moveToData = [
        { value: '', text: 'Move To' },
        { value: 'Item1', text: 'Item1' },
        { value: 'Item2', text: 'Item2' },
        { value: 'Item3', text: 'Item3' },
    ];


    const [formData, setFormData] = useState({
        fullName: '',
        moveFrom: '',
        moveTo: '',
        phoneNumber: '',
        emailId: '',
        shippingAddress: '',
        date: '',
        time: '',
        zipCode: '',
        message: '',

    })
    // const [moveFrom, setMoveFrom] = useState(moveFromData[0].value)
    // const [moveTo, setMoveTo] = useState(moveToData[0].value)
    const [alert, setAlert] = useState('')
    // const [message, setMessage] = useState('')
    const [hide, setHide] = useState(false)

    const handleSubmit = async (e) =>
    {
        e.preventDefault();

        console.log(formData)
        // console.log("heelo :", moveFrom)
        // console.log(moveTo)

        console.log('submit')
        try
        {
            const { error } = await supabase
                .from('Request_Quote_Shipping_Info')
                .insert({ ...formData })

            if (error)
            {
                console.log(error)
                setAlert('error')
                // setMessage('Error! Your Request is failed')
                setHide(true)
            } else
            {
                console.log("submit")
                setAlert('success')
                // setMessage('Your Request was successfully')
                setHide(true)
                setFormData({
                    fullName: '',
                    moveFrom: '',
                    moveTo: '',
                    phoneNumber: '',
                    emailId: '',
                    shippingAddress: '',
                    date: '',
                    time: '',
                    message: '',
                })
            }

        } catch (error)
        {
            console.log(error)
            setAlert('error')
            setHide(true)
            // setMessage(error.message)
        }

        setTimeout(() =>
        {
            setHide(false)
        }, 2000)

    }
    const handleChange = e =>
    {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // setMoveFrom({ ...formData, [e.target.name]: e.target.value })
        // setMoveTo(e.target.value)
        // console.log(process.env.REACT_APP_URL, process.env.REACT_APP_API_ID)
    };

    return (
        <>
            {/* <div className=' fixed top-1/3 w-full z-30'>
                <div className={` alert alert-${alert} ${hide ? '' : 'hidden'}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    <span>{message}</span>
                </div>
            </div> */}
            <div className='flex justify-center'>
                <div className=' p-8 w-full rounded-bl-3xl shadow-2xl bg-white' >
                    <h1 className='text-4xl font-bold py-4 sm:text-center'>Shipping Information</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="grid gap-6 mb-6 grid-cols-3 sm:grid-cols-1">
                            <div>
                                <input type="text" id="name" name='fullName' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Full Name" required onChange={handleChange} />
                            </div>
                            <div>
                            <input type="text" id="move_from" name='moveFrom' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Move From" required onChange={handleChange} />
                                {/* <select value={formData.moveFrom} id="move_from" name='moveFrom' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " onChange={handleChange}>

                                    {moveFromData.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select> */}
                            </div>
                            <div>
                            <input type="text" id="move_to" name='moveTo' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Move To" required onChange={handleChange} />
                                {/* <select value={formData.moveTo} id="move_to" name='moveTo' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " onChange={handleChange}>

                                    {moveToData.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select> */}
                            </div>

                        </div>
                        <div className="grid gap-6 mb-6 grid-cols-3 sm:grid-cols-1">
                            <div>
                                <input type="tel" name='phoneNumber' id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Phone Number" required onChange={handleChange} />
                            </div>
                            <div className="mb-6 sm:mb-2">
                                <input type="email" name='emailId' id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="john.doe@company.com" required onChange={handleChange} />
                            </div>
                            <div>
                                <input type="text" name='shippingAddress' id="shipping" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Shipping Address" required onChange={handleChange} />
                            </div>
                        </div>
                        <div className="grid gap-6 mb-6 grid-cols-3 sm:grid-cols-1">
                            <div>
                                <input type="date" name='date' id="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Date" required onChange={handleChange} />
                            </div>
                            <div>
                                <input type="time" name='time' id="time" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Time" required onChange={handleChange} />
                            </div>
                            <div className="mb-6 sm:mb-2">
                                <input type="number" name='zipCode' id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Zip Code" required onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-6 sm:mb-2">

                            <textarea id="message" name='message' rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="Write your thoughts here..." onChange={handleChange}></textarea>
                        </div>
                        <div className='m-4'>
                            <button type='submit' className='bg-[#ffb500] hover:bg-[#052D52] hover:text-white p-4 rounded-bl-2xl font-bold'>Get A Quote</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default RequestForm