import React, { useEffect } from 'react'

export const CustomerProtection = () => {

  useEffect(()=>{
    window.scroll(0,0)
  },[])


  return (
    <div className=' max-w-[1700px] m-auto'>
      <div className='w-10/12 sm:w-11/12 m-auto leading-7 my-10 flex flex-col gap-2'>
        <h2 className='text-2xl sm:text-xl mb-2 text-red-600 font-semibold text-center uppercase'>Customer Protection Policy</h2>
        <p>
          Pack4Movers Ltd. maintain personal information about customers and transferees related to their relocation and moving. Pack4Movers is committed to respect its customers by handling all the personal information collected and taking necessary measures to ensure the security, confidentiality and discretion of personal data and prevent that they are altered, transmitted or used by persons with unauthorized access in accordance with applicable local laws as well as our own Privacy Policy.

          This document explains our Data Protection procedure and addresses 10 privacy principles. These 10 privacy principles are essential to the proper protection and management of our customer’s personal and sensitive information. These principles are based on internationally known fair information practices included in many privacy laws and regulations of various jurisdictions around the world and recognized good privacy practices.
        </p>
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>1. MANAGEMENT</h4>
          <p>We will, through appropriate management and strict application of criteria and controls:</p>
          <ul className=' list-disc pl-5'>
            <li>Observe fully conditions regarding the fair collection and use of information.</li>
            <li>Meet our legal obligations to specify the purposes for which information is used.</li>
            <li>Collect and process appropriate information, and only to the extent that it is needed to fulfill our operational needs or to comply with any legal requirements.</li>
          </ul>
          <p>We ensure that we have a responsible person (Quality Manager) in Pack4Movers for ensuring compliance with up to date Data Privacy Protection regulations.</p>
          <p>We ensure that everyone processing personal information understands that they are contractually responsible for following good data</p>
          <p>protection practice aligned with internal procedures and legal requirements.</p>
          <p>We collect, process and transfer personal information about customers through computerized and paper-based data processing systems.</p>
          <p>We ensure that all processing and transfers of personal information of our customers and transferees are subject to reasonable confidentiality and privacy safeguards.</p>
        </div>
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>2. NOTICE:</h4>
          <p>We provide notice about our privacy policies, terms and conditions on our company website under the quality section. We are committed to respect all our customers by handling all their personal information collected in connection with their relocation in accordance with applicable law as well as our own privacy policies. We only process personal information to accommodate the customer with their respective to their relocation. We may process sensitive information if it is needed to carry out their successful relocation or if it is required to comply with the applicable law. In general personal and/or sensitive information will not be collected, processed or transferred, except where adequate privacy protection mechanisms are in place.</p>
        </div>
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>3. CHOICE AND CONSENT:</h4>
          <p>By confirming use of our service by accepting our quotation you give your explicit consent with respect to the collection, use, and disclosure of personal information as described in this notice. Explicit consent here means you were clearly presented with an option to agree or disagree with the collection, use, or disclosure of personal information.</p>
        </div>        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>4. COLLECTION:</h4>
          <p>We shall obtain and process personal data fairly and in accordance with statutory and other legal obligations. We collect personal information for the sole purposes to accommodate transferees before, during and after their entire relocation process. Personal information can be collected from the transferee, his family members, his employer and/or
            <p>moving companies or origin/destination agents related to their relocation process.</p></p>
        </div>        
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>5. USE, RETENTION AND DISPOSAL:</h4>
          <p>We limit the use of personal information to the sole purpose of executing your relocation and for which you have given your explicit consent at the time of your acceptance of our quotation. We retain personal information for only as long as necessary to fulfill the stated purposes or as required by law or regulations and thereafter appropriately dispose of such information</p>
        </div>
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>6. ACCESS:</h4>
          <p>You may reasonably access and update your personal information by contacting the Pack4Movers. This notice provides basic information about our processing of your personal information and your privacy rights. Should you have additional questions, you may contact the responsible person at Pack4Movers Packers and Movers Ltd as follows:</p>
          <span><p className=' font-semibold'>Name of the person:</p></span>
          <span><p className=' font-semibold'>Designation:</p></span>
          <span><p className=' font-semibold'>Email:</p></span>
          <span><p className=' font-semibold'>Tel:</p></span>

        </div>        
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>7. DISCLOSURE TO THIRD PARTIES:</h4>
          <p>We shall use and disclose your personal data only in circumstances that are necessary for the purposes for which we collected the data. We will use this information to provide packing, transportation and storage of your household goods and to inform of the status and may provide your information to third party in the supply chain, which are related to administrative and operational activities of your relocation. We will never sell your personal information to third parties.</p>
        </div>        
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>8. SECURITY AND PRIVACY:</h4>
          <p>We protect personal data against unauthorized access (both physical and digital) aligned with our internal IT policy and procedures. We shall take appropriate security measures against unauthorized access to, or alteration, disclosure or destruction of personal data and against its accidental loss or destruction. Personal data will only be accessible to Pack4Movers authorized staff.</p>
        </div>        
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>9. QUALITY</h4>
          <p>We maintain accurate, complete, and relevant personal information as reasonable possible and only for the purposes identified in this notice. We retrieve your personal data from the owner filled owner’s information form and from the corresponding e-mails. Please note that we have shared responsibility with regard to the accuracy of your personal information. Please let us know of any changes to your personal information.</p>
        </div>
        <div className=' flex flex-col gap-1'>
          <h4 className=' font-semibold'>10. MONITORING AND ENFORCEMENT:</h4>
          <p>We monitor compliance with our privacy policies and procedures and have procedures to address privacy related complaints and disputes. All Pack4Movers employees are aware that a breach of the rules and procedures identified in this policy may lead to disciplinary action being taken against them. If you believe that your personal information is not handled in accordance with the applicable law or our privacy policies, you may submit a complaint to the Pack4Movers responsible person who will investigate the complaint.</p>
        </div>
        <p className=' font-semibold'>This Data Protection Policy will be reviewed regularly in light of any legislative or other relevant developments.</p>
      </div>
    </div>
  )
}
