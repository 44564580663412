import React, { useEffect } from 'react'
import styles from '../components/LandingPage/style.module.css'
import Herosection from '../components/TestimonialPage/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import OurWork from '../components/LandingPage/OurWork/OurWork'
import Testimonial from '../components/LandingPage/Testimonial/Testimonial'
import { Reviews } from '../components/TestimonialPage/Reviews/Reviews'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

export const TestimonialPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Testimonial Page" });
    window.scroll(0, 0)
  }, [])
console.log("Testimonial" ,process.env.REACT_APP_GA_KEY)

  return (
    <div className={styles.herosection__background}>
      <Herosection />
      {/* <GetQuote bg /> */}
      <Testimonial />
      <OurWork />
      <Reviews />

    </div>
  )
}
