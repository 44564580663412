import React, { useEffect } from 'react'

const Policy = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div>
            <div className='w-10/12 mx-auto md:w-11/12 sm:w-11/12 mt-7'>
                <h2 className='text-3xl font-bold text-center uppercase'>
                    Privacy Policy
                </h2>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        The Short Version
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        Pack4Movers and Movers Ltd do not rent, sell, or give out your information to anyone outside of the company or its authorized agents. Only  Pack4Movers  Ltd. or an agent working on behalf of  Pack4Movers  Ltd. may have access to information gathered through this web site.  Pack4Movers  Ltd. uses this information to provide services tailored to the interests of our users. One of the uses of your information may be for mailing purposes. You may request any time to be removed from our mailing list or email list by using the procedures described below.
                        <p className='mt-2'>

                            You can visit most of our website without having to give any information about yourself. The full text of our Privacy Policy is below:
                        </p>
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        Full Text Version
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        Pack4Movers  Ltd. has created this privacy statement in order to demonstrate our firm commitment to privacy. The following discloses the information gathering and dissemination practices for all domains owned by  Pack4Movers  Ltd.
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        Security
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        In Services of our site where sensitive information may be transferred, all transmissions between client and server are encrypted using the industry standard SSL protocol. We also restrict the level of access that our employees have to user's information, based on their responsibilities and hold them to high levels of confidentiality.
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        Information Collection and Use
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        Pack4Movers  Ltd. is the sole owner of the information collected on this site. We will not sell, share, or rent this information to others in any way different from what is disclosed in this statement.  Pack4Movers  Ltd. collects information from our users at several different points on our website. The purpose of this information is outlined on the page where this information is collected. Information collected via this website is used to provide the services offered on that area of the site.
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        Links To Other Sites
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        This site contains links to other sites.  Pack4Movers  Ltd. is not responsible for the privacy practices or the content of such web sites.
                        <p className='mt-2'>
                            Mail your request to : Ground Floor G-1 Shree prasad Vihar Near BSNL Tower Godhani Nagpur 441501
                        </p>
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>
                        Contacting The Website Administrators
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        If you have any questions about this privacy statement, the practices of this site, or your dealings with this web site, please contact:
                    </h6>
                </div>
                <div className='py-3 sm:text-sm'>
                    <h3 className='text-2xl font-semibold'>

                        Environmental Policy
                    </h3>
                    <h6 className='mt-4 leading-7'>
                        APML’S environmental responsibilities are driven by our commitment to preserve the environment and are integral to the way we do business.
                        <div className='w-[97%] mx-auto mt-2'>
                            <ol className='list-decimal'>
                                <li>
                                    We are committed to deal proactively with Climate Change issue by efficient use of natural resources & energy; reducing and preventing pollution; promoting waste avoidance and recycling measures; and product stewardship.
                                    <div className='flex flex-col gap-2 mt-2 font-semibold'>

                                        <p>
                                            We shall identify, assess and manage our environment impact.
                                        </p>
                                        We shall regularly monitor, review and report publicly our environmental performance.

                                        <p>
                                            We shall enhance awareness, skill and competence of our employees and contractors so as to enable them to demonstrate their involvement, responsibility and accountability for sound environmental performance.
                                        </p>
                                    </div>
                                </li>
                                <li className='mt-5'>
                                    We are committed to continual improvement in our environmental performance.
                                    <p className='mt-2 font-semibold'>
                                        We shall set objective-targets, develop, implement and maintain management standards and systems, and go beyond compliance of the relevant industry standards, legal and other requirements.
                                    </p>
                                </li>
                                <li className='mt-5'>
                                    We will truly succeed when we sustain our environmental achievement and are valued by the communities in which we work.
                                </li>
                            </ol>
                        </div>

                    </h6>
                </div>

            </div>
        </div>
    )
}

export default Policy