import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';


// import required modules
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';

const heroArray = [
    {
        title: 'Welcome to pack4movers.',
        subHeading: 'Swift and Reliable Relocation Solutions',
        desc: 'Rest assured and relocate with peace of mind, knowing that our proficient team will deliver prompt and dependable moving services customized to your specific requirements.'
    },
    {
        title: 'Need to Move?',
        subHeading: 'Streamlining Your Relocation with Efficiency and Precision',
        desc: 'Experience a stress-free and secure relocation with our adept team, providing prompt and dependable moving services customized to match your requirements.'
    },
    {
        title: 'Tired of the inconvenience?',
        subHeading: 'Efficient and Safe Relocation Service Provider',
        desc: 'Experience a stress-free and worry-free relocation with our adept team, providing speedy and dependable moving services customised to suit your requirements.'
    },
]

const Herosection = () =>
{
    return (
        <section className='bg-[#002E5ADB]/80 z-0 overflow-hidden'>
            <div className='w-11/12 h-[75vh] m-auto flex justify-center items-center text-center sm:h-[50vh]'
                data-aos='fade-down'
                data-aos-duration='1000'
            >
                <div className='w-8/12 m-auto text-white md:w-full sm:w-full overflow-y-hidden self-center pt%]'>

                    <Swiper
                        // direction={'vertical'}
                        pagination={{
                            clickable: false,
                        }}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {
                            heroArray?.map((ele, i) => <SwiperSlide key={i}>
                                <Card  {...ele} />
                            </SwiperSlide>
                            )
                        }
                    </Swiper>

                </div>
            </div>
        </section>
    )
}

export default Herosection


const Card = ({ title, subHeading, desc }) =>
{
    return (
        <>
            <h1 className='my-4 font-medium'
                data-aos='fade-down'
                data-aos-duration='1000'
            >
                {title}
            </h1>
            <h2 className='text-6xl font-semibold md:text-5xl sm:text-2xl'
                data-aos='fade-down'
                data-aos-duration='1000'
                data-aos-delay='200'
            >
                {subHeading}
            </h2>
            <h4 className='pt-6 sm:text-sm'
                data-aos='fade-down'
                data-aos-duration='1000'
                data-aos-delay='200'
            >
                {desc}
            </h4>
            <div className='py-10'>
                <Link to={'/contact'} className='p-4 bg-[#ffb500] text-black hover:bg-white hover:text-[#ffb500] transition-all ease-in duration-200 font-medium rounded-bl-2xl'>
                    Contact Us
                </Link>
            </div>
        </>
    )
}