import React from "react";

function ImgBack() {
  return (
    <section className="bg-[#002E5ADB]/80  bg-center z-0 overflow-x-hidden">
      <div className="w-11/12 h-[50vh] sm:h-[20vh] m-auto flex justify-start items-center text-center  "
        data-aos='fade-down'
        data-aos-duration='1000'>
        <div className="w-4/12 m-auto text-white md:w-full sm:w-full">
          <h2 className="text-6xl font-semibold md:text-5xl sm:text-5xl">
            OUR SERVICES
          </h2>
        </div>
      </div>
    </section>
  );
}

export default ImgBack;
