import React from 'react'

import clipboard from '../../../Assets/CardImg/Landingpage/clipboard.svg'
import headphone from '../../../Assets/CardImg/Landingpage/service.svg'
import { Link } from 'react-router-dom'


const cardArray = [
    {
        img: clipboard,
        ServiceName: "Free Estimate",
        desc: "Explore the expense of your upcoming relocation endeavour at no cost using our complimentary Free Estimate feature.",
        alt: 'Clipboard'
    },
    {
        img: headphone,
        ServiceName: "24/7 Services",
        desc: "The comprehensive service guarantees 24/7 accessibility, delivering aid and assistance around the clock.",
        alt: 'headPhone'
    }
]

const AboutUs = () => {
    return (
        <section className='relative bg-white overflow-x-hidden'>
            <div className='w-11/12 m-auto flex md:flex-col sm:flex-col'>
                <div className='w-11/12 bg-[#002E5A] rounded-bl-[4rem] py-16 sm:pb-[40%] px-6 md:w-full sm:w-full'>
                    <h2 className='uppercase text-[#ffb500] font-semibold text-2xl'
                        data-aos='fade-down-left'
                        data-aos-duration='800'
                    >
                        About Us
                    </h2>
                    <h4 className='w-10/12 sm:w-full text-white font-semibold text-5xl mt-4'
                        data-aos='fade-up'
                        data-aos-duration='1000'
                        data-aos-delay='150'
                    >
                        Ensuring the Secure Relocation of Your Precious Possessions

                    </h4>
                    <div
                        data-aos='fade-left'
                        data-aos-duration='1000'
                        data-aos-delay='200'
                    >

                        <p className='w-8/12 sm:w-full text-white mt-4 '


                        >
                            Pack4Movers provides unmatched domestic moving solutions customized to your precise needs. Our distinctive and exceptional approach ensures a seamless household relocation experience, whether you are moving within India or across regions.
                        </p>
                        <div>
                            {
                                cardArray?.map((ele, i) => {
                                    return (
                                        <div key={i} className='flex gap-4 my-6'

                                        >
                                            <div className='rounded-bl-xl flex justify-center items-center w-16'
                                            // data-aos='fade-right'
                                            // data-aos-duration='800'
                                            >
                                                <img src={ele.img} alt={ele.alt} />
                                            </div>
                                            <div className='w-7/12 md:w-full sm:w-full'
                                            // data-aos='fade-left'
                                            // data-aos-duration='800'
                                            >
                                                <p className='text-xl text-white font-semibold '>
                                                    {ele.ServiceName}
                                                </p>
                                                <p className='text-white text-sm'>
                                                    {ele.desc}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='pt-5'>
                            <Link to='/request-quote' className='text-black bg-[#ffb500] hover:bg-white focus:ring-4 text-md w-1/2 sm:w-auto text-center  p-4 font-bold px-6 hover:text-[#ffb500] transition-all ease-in duration-200 rounded-bl-2xl'>
                                Get Quote
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end items-center'
                    data-aos='fade-left'
                    data-aos-duration='800'
                >
                    <div className='relative w-full -translate-x-[20%] bg-red-200 -mb-16 md:-translate-x-0 md:mx-auto md:w-1/2 md:-translate-y-1/4 sm:mx-auto sm:w-[70%] sm:-translate-y-1/2 sm:translate-x-0'
                    >
                        <img src={'https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/about_section_img.jpg'} alt="people Group" className='rounded-bl-[4rem] w-full' />

                    </div>

                </div>

            </div>
        </section>
    )
}

export default AboutUs