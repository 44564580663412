import React from 'react'
import AddBlog from '../components/AddBlogs/AddBlog'

const AddBlogPage = () => {
  return (
    <div>
      <AddBlog/>
    </div>
  )
}

export default AddBlogPage
