import React from 'react'
import GetStrImg from '../../Assets/Images/contactpage/Truck-img-large.png'
import { Link } from 'react-router-dom'

const Page3 = () =>
{
    return (
        <div className='bg-[#f8f8f8] mt-0 mb-0 py-6' >
            <div className='m-12 h-full flex justify-center items-cente  '>
                <div className='flex w-full md:flex-col sm:flex-col xs:flex-col md:justify-center items-center'>

                    <div className='w-6/12 flex flex-col md:items-center md:text-center p-2 sm:text-center xs:text-center sm:w-screen xs:w-screen md:mb-12 sm:mb-12 xs:mb-12  ' >
                        <h3 className='text-3xl font-semibold p-2 sm:text-2xl text-[#002E5A]' data-aos="zoom-in-down" data-aos-duration='600'>GET STARTED</h3>
                        <h1 className='text-5xl font-bold p-2 sm:text-3xl' data-aos="zoom-in">Ready To Get Moving? Get Your Free Quote</h1>
                        <div className='mt-4 p-2' data-aos="fade-right" data-aos-duration='800'>
                            <button className='bg-[#ffb500] text-black hover:bg-white hover:text-black p-4 rounded-bl-2xl font-bold transition-all duration-200 ease-linear' type='button' >Contact Us</button>
                        </div>
                    </div>
                    <div className='w-6/12 md:w-screen  sm:w-screen xs:w-screen '>
                        <div className='flex justify-center' data-aos="fade-left" data-aos-duration='1000'>

                            <img src={GetStrImg} style={{ width: '100%' }} alt="Image of truck wich has company logo on it" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page3