import React, { useEffect, useState } from 'react'
import { supabase } from '../Supabase/Config'
import { Link, useParams } from 'react-router-dom'
import { FaSquareXTwitter, FaLinkedin, FaSquareGithub } from 'react-icons/fa6'
import MDEditor from '@uiw/react-md-editor';
import { Hourglass, Oval } from 'react-loader-spinner';

const BlogDetailPage = () => {
    const params = useParams()
    const [blogDetailData, setBlogDetailData] = useState()
    const [isLoading, setIsLoding] = useState(true)

    // console.log(params.id)
    const getData = async () => {
        setIsLoding(true)
        let { data, error } = await supabase
            .from('blog-data')
            .select('*')
            .eq('id', params.id)
        setBlogDetailData(data[0])
        setIsLoding(false)
    }

    useEffect(() => {
        window.scroll(0, 0)
        setTimeout(() => {
            getData()
        }, 2000)
    }, [params.id])

    return (
        <div className='bg-white my-16 sm:my-10'>
            <div className='w-10/12 m-auto'>
                {
                    isLoading ?
                        <div className='flex justify-center items-center h-[40vh]'>
                            <Oval
                                height={80}
                                width={80}
                                color="#ffb500"
                                wrapperStyle={{}}
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#ffb500"
                                strokeWidth={5}
                                strokeWidthSecondary={2}

                            />
                        </div>
                        :
                        <div>
                            {
                                blogDetailData ?
                                    (
                                        <div>
                                            <div>
                                                <img src={blogDetailData?.imageUrl} alt={blogDetailData[0]?.title} className='aspect-[2] w-full' />
                                            </div>
                                            <div className='w-[62%] md:w-full sm:w-full m-auto flex flex-col gap-3 my-4'>
                                                <h1 className=' text-4xl md:text-3xl sm:text-2xl font-bold'>{blogDetailData?.title}</h1>
                                                <h3 className=' text-xl sm:text-base font-medium'>{blogDetailData?.description}</h3>
                                                <div className='flex justify-between flex-wrap items-center'>
                                                    <div className='flex items-center gap-3'>
                                                        <h3 className=' text-lg font-bold'>{blogDetailData?.publisherName}</h3>
                                                        <Link to={blogDetailData?.publisherGithub} target='_main'><FaSquareGithub size={26} /></Link>
                                                        <Link to={blogDetailData?.publisherLinkedin} target='_main'><FaLinkedin size={26} /></Link>     <Link to={blogDetailData?.publisherTwitter} target='_main'><FaSquareXTwitter size={26} /></Link>
                                                    </div>
                                                    <p className=" font-semibold">{new Date(blogDetailData?.createdDate).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                                </div>
                                                <div className='h-[2px] bg-[#ffb500] mb-2'></div>
                                                <MDEditor.Markdown source={blogDetailData?.markdownData} style={{ whiteSpace: 'pre-wrap' }} className={`${'markdownMainContainer'} !whitespace-normal `} />
                                            </div>
                                        </div>
                                    )
                                    : "Data Not Found!"
                            }
                        </div>
                }
            </div>
        </div>
    )
}

export default BlogDetailPage
