import React from "react";
import Accordion from "../Accordion/Accordion";

function Faq() {
  return (
    <>
      <div className="bg-white p-1 pb-64  md:pb-[0rem] sm:pb-[0rem]" id="faqForm">
        <div className="bg-[#002E5A] md:min-h-[33vh] sm:min-h-[50vh] rounded-bl-3xl mx-2  mt-20 md:mt-6 sm:mt-0">
          <div
            className="container mx-auto flex md:flex-col sm:flex-col sm:items-center"
            data-aos="fade-up"
          >
            <div className="lg:w-2/3 sm:hidden md:hidden relative">
              <img
                src="https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/faq_person_image.png"
                alt="Person image"
                className="-mt-[10%] "
              />
            </div>
            <div className="w-3/4 md:w-full sm:w-full mx-auto">
              <div className="max-w-screen-xl mx-auto px-5 min-h-sceen relative w-full">
                <div className="flex flex-col">
                  <h2
                    className="sm:mb-5 md:mb-5 md:text-center sm:text-center text-center tracking-tight w-full md:w-7/12 mx-auto sm:w-full text-white font-semibold text-5xl sm:text-3xl mt-4"
                  // data-aos="fade-down-left"
                  // data-aos-duration="800"
                  >
                    Frequently Asked Questions
                  </h2>
                  <div className="sm:block md:block hidden m-auto relative">
                    <img
                      src="https://template-kit.evonicmedia.com/layout5/wp-content/uploads/2022/04/faq_person_image.png"
                      alt="person image"
                    />
                  </div>
                </div>
                <div className="sm:hidden md:hidden divide-y md:left-1/2 md:!-translate-x-[50%] sm:left-1/2 sm:!-translate-x-[50%] divide-neutral-200 mx-auto mt-8 sm:mt-0 md:mt-0 shadow-xl bg-white absolute rounded-bl-3xl">
                  <Accordion />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:block md:block hidden bg-white">
        <Accordion />
      </div>
    </>
  );
}

export default Faq;



