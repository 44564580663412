import React, { useEffect } from 'react'
import AboutUs from '../components/LandingPage/AboutUs/AboutUs'
import AboutUsVideo from '../components/LandingPage/AboutUs/AboutUsVideo'
import Herosection from '../components/AbousUs/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import styles from '../components/LandingPage/style.module.css'
import OurProcess from '../components/LandingPage/OurProcess/OurProcess'
import OurWork from '../components/LandingPage/OurWork/OurWork'
import Quote from '../components/ServicePage/Quote/Quote'
import OurProjects from '../components/LandingPage/OurProjects/OurProjects'
import Team from '../components/LandingPage/Team/Team'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const AboutPage = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "About Page" });
        window.scroll(0, 0)
    }, [])

    return (
        <div className={styles.herosection__background}>
            <Herosection />
            <GetQuote bg />
            <AboutUs />
            <OurProcess />
            <AboutUsVideo />
            {/* <OurWork /> */}
            <Quote />
            <OurProjects />
            {/* <Team /> */}
        </div>
    )
}

export default AboutPage