import React from 'react'

const Herosection = () => {
    return (
        <section className=' bg-[#052D51]/80 z-0'>
            <div className='h-[50vh] sm:h-[20vh] text-center flex justify-center items-center text-white'>
                <h1 className='text-6xl font-bold sm:text-5xl'>
                    Testimonial
                </h1>

            </div>

        </section>
    )
}

export default Herosection
