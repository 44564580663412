import React, { useEffect } from 'react'
import styles from '../components/LandingPage/style.module.css'
import { Link } from 'react-router-dom'

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

function Error() {

   useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Error Page" });
        window.scroll(0, 0)
      }, [])

    return (
        <div className={`flex flex-col bg-white justify-center bg-no-repeat bg-fixed bg-cover bg-center ${styles.error__background}`}>
            <section className=' bg-[#273679DB]/80 z-0 overflow-x-hidden'>
                <div className='w-11/12 h-screen m-auto flex justify-start items-center text-center sm:h-[50vh]'
                    data-aos='fade-down'
                    data-aos-duration='1000'
                >
                    <div className='w-8/12 m-auto text-white md:w-full sm:w-full'>
                        <h1 className='my-4 font-medium'>
                            ERROR 404
                        </h1>
                        <h2 className='text-6xl font-semibold md:text-5xl sm:text-3xl'>
                            Oops! Page Not Found!
                        </h2>
                        <div className='mt-10'>
                            <Link to='/' className='p-4 bg-[#ffb500] text-black hover:bg-[#052D52] hover:text-white transition-all ease-in duration-200 font-medium rounded-bl-2xl'>
                                Back To Home Page
                            </Link>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Error
