import React from 'react'

import Member from '../../LandingPage/Team/MemberCard/Member';
import AtharvaDhakulkar from '../../../Assets/Team/AtharvDhakulkar.jpg';
import PraveenBhaghvatula from '../../../Assets/Team/Pravin.png';
import ShivPrasad from '../../../Assets/Team/ShivPrashad.jpg';
import YashWankhade from '../../../Assets/Team/YashWankhade.png';
import SumitChawke from '../../../Assets/Team/Sumit.png';

const cardArray = [
    {
        img: AtharvaDhakulkar,
        name: 'Atharva Dhakulkar',
        designation: "Project Manager",
        instaLink: "#",
        fbLink: "#",
        googleLink: "#",
        twitterLink: "#",
    },
    {
        img: PraveenBhaghvatula,
        name: 'Praveen Bhaghvatula ',
        designation: "Entrepreneur",
        instaLink: "#",
        fbLink: "#",
        googleLink: "#",
        twitterLink: "#",
    },
    {
        img: ShivPrasad,
        name: 'ShivPrasad',
        designation: "Sales Executive",
        instaLink: "#",
        fbLink: "#",
        googleLink: "#",
        twitterLink: "#",
    },
    {
        img: YashWankhade,
        name: 'Yash Wankhade ',
        designation: "Analyst",
        instaLink: "#",
        fbLink: "#",
        googleLink: "#",
        twitterLink: "#",
    },
    {
        img: SumitChawke,
        name: 'Sumit Chawke',
        designation: "HR",
        instaLink: "#",
        fbLink: "#",
        googleLink: "#",
        twitterLink: "#",
    },

]

const OurTeam = () => {
    return (
        <section className='bg-white overflow-x-hidden'>
            <div className="w-10/12 m-auto md:w-11/12 sm:w-full py-12">
                <div className='text-center'>
                    <h2 className='text-2xl uppercase text-[#ffb500] font-medium'
                        data-aos='fade-down'
                        data-aos-duration='800'
                    >
                        Our Team
                    </h2>
                    <h4 className='text-5xl font-medium mt-3 sm:text-3xl sm:mt-1'
                        data-aos='fade-up'
                        data-aos-duration='800'
                    >
                        Our Dedicated Team
                    </h4>
                    <div className='flex justify-evenly items-center flex-wrap mt-10 gap-y-10  sm:mt-0 sm:items-stretch'
                        data-aos='fade-right'
                        data-aos-duration='800'
                    >
                        {
                            cardArray?.map((ele, i) => <Member key={i} {...ele} />)
                        }
                    </div>
                </div>
            </div>

        </section>
    )
}

export default OurTeam