import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'

import TreeIcon from '../../../Assets/SVG_Image/TreeTimeline.svg'
import CardImg1 from '../../../Assets/CardImg/Landingpage/checkbox-with-tick.svg'
import CardImg2 from '../../../Assets/CardImg/Landingpage/TravelInsurance.svg'
import CardImg3 from '../../../Assets/CardImg/Landingpage/Heavy.svg'

const cardArray = [
    {
        img: CardImg1,
        desc: "The Contact Office at Pack4Movers acts as your central hub for communication and support during the entire course of your moving experience.",
        title: "Contact Office"
    },
    {
        img: CardImg2,
        desc: "The packing day with Pack4Movers is a meticulously coordinated and highly efficient operation. Our skilled crew comes to your site fully equipped with all the essential packing supplies and gear.",
        title: "Packing Day"
    },
    {
        img: CardImg3,
        desc: "The day of the move marks the culmination of thorough planning and preparation, bringing the entire relocation procedure into action.",
        title: "Moving Day"
    },

]

const OurProcess = () => {
    return (
        <section className='bg-white overflow-x-hidden'>
            <div className='w-10/12 m-auto md:w-11/12 sm:w-11/12 py-10'>
                <div>
                    <h3 className='text-center text-3xl text-[#ffb500] font-bold uppercase sm:text-2xl xs:text-2xl'
                        data-aos='fade-down'
                        data-aos-duration='800'
                    >
                        Our Process
                    </h3>
                    <h6 className='text-5xl font-semibold text-center mt-2 sm:text-xl xs:text-xl'
                        data-aos='fade-up'
                        data-aos-duration='800'
                    >
                        How We Works?
                    </h6>
                </div>
                <div className='flex flex-col justify-center items-center mt-12 sm:mt-6 xs:mt-6'
                    data-aos='zoom-in'
                    data-aos-duration='800'
                >
                    <img src={TreeIcon} alt="Tree TimeLine" className='sm:hidden' />
                    <div className='flex -mt-4 justify-evenly sm:flex-col'>
                        {
                            cardArray?.map((ele, i) => <Card key={i} {...ele} id={i} />)
                        }
                    </div>
                </div>
            </div>

        </section>
    )
}

export default OurProcess

const Card = ({ img, desc, title, id }) => {
    return (
        <div className='flex flex-col justify-between text-white  bg-[#002E5A] w-[30%] rounded-bl-3xl hover:shadow-2xl transition-all duration-300 ease-linear border border-[#ffb500] px-8 py-8 my-4 sm:w-full'
            data-aos='fade-down'
            data-aos-duration='1500'
            data-aos-delay={`${id * 2}00`}
        >
            <div>
                <h3 className='text-2xl font-semibold my-2 md:text-xl align-top'
                    data-aos='fade-down'
                    data-aos-duration='800'
                >
                    {title}
                </h3>
                <h6 className='text-sm '
                    data-aos='fade-up'
                    data-aos-duration='800'
                >
                    {desc}
                </h6>
            </div>

            <div className='flex justify-between items-center mt-4'
                data-aos='zoom-in'
                data-aos-duration='800'
            >
                <img src={img} alt={title} className='h-14' />
                <AiOutlineArrowRight color='#ffb500' fontSize={30} />
            </div>
        </div>
    )
}