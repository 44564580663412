import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'

import home from '../../../Assets/CardImg/Landingpage/Home.svg'
import earth from '../../../Assets/CardImg/Landingpage/RotatingGlobe.svg'
import warehouse from '../../../Assets/CardImg/Landingpage/Warehouse.svg'
import truck from '../../../Assets/CardImg/Landingpage/SemiTruck.svg'
import office from '../../../Assets/CardImg/Landingpage/Office.svg'
import chart from '../../../Assets/CardImg/Landingpage/Easel.svg'

const cardArray = [
    {
        img: home,
        desc: "Proficient in ensuring the seamless relocation of your residential possessions.Global Moving, We offer overseas moving solutions to facilitate your global relocation needs.",
        title: "Home Relocation",
        alt: 'house'
    },
    {
        img: earth,
        desc: "We provide international relocation services for your convenience.",
        title: "Global Moving",
        alt: 'rotating earth'
    },
    {
        img: warehouse,
        desc: "Our storage solutions are designed to meet all your packing and warehousing requirements.",
        title: "Storage Solutions",
        alt: 'warehouse'
    },
    {
        img: truck,
        desc: "Ensure the secure transportation of your vehicles through our car carrier service.",
        title: "Vehicle Transportation",
        alt: 'truck'
    },
    {
        img: office,
        desc: "Relocating Your Office and Corporate Spaces",
        title: "Residential Movers",
        alt: 'office'
    },
    {
        img: chart,
        desc: "Place your confidence in our expertise when it comes to the transportation of valuable fine arts and sculptures.",
        title: "Art and Sculpture Relocation:",
        alt: 'chart'
    },
]

const OurServices = () => {
    return (
        <section className='bg-white py-6 pt-10 xs:pt-6'>
            <div className='w-11/12 m-auto'>
                <div>
                    <h3 className='text-center uppercase text-[#002E5ADB] font-bold text-4xl sm:text-2xl xs:text-2xl'
                        data-aos='zoom-in'
                        data-aos-duration='700'
                    >
                        our services
                    </h3>
                    <h6 className='w-8/12 text-2xl font-semibold text-center m-auto my-4 md:w-full md:text-4xl sm:w-full sm:text-lg xs:text-lg'
                        data-aos='fade-up'
                        data-aos-duration='700'
                    >
                        We Provide World Class Logistic Services
                    </h6>
                </div>
                <div className='flex justify-evenly flex-wrap'
                    data-aos='fade-right'
                    data-aos-duration='600'
                >
                    {
                        cardArray.map((ele, i) => <Card key={i} {...ele} />)
                    }
                </div>

            </div>
        </section>
    )
}

export default OurServices

const Card = ({ img, desc, title }) => {
    return (
        <div className='flex flex-col justify-between bg-[#002E5A] text-white w-[30%] md:w-[40%] sm:w-full rounded-bl-3xl hover:shadow-2xl transition-all overflow-x-hidden duration-300 ease-linear border border-[#ffb500] px-8 py-8 my-4'

        >
            <h3 className='text-2xl font-semibold my-2'
                data-aos='fade-up'
                data-aos-duration='800'
            >
                {title}
            </h3>
            <h6
            // data-aos='fade-up'
            // data-aos-duration='800'
            >
                {desc}
            </h6>
            <div className='flex justify-between items-center mt-4'
                data-aos='zoom-in'
                data-aos-duration='800'
            >
                <img src={img} alt={title} className='h-14' />
                <AiOutlineArrowRight color='#ffb500' fontSize={30} />
            </div>
        </div>
    )
}