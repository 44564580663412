import React from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../Assets/logo/pack-4-movers-Logo.png";
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs'
import { IoLogoYoutube } from 'react-icons/io'
import { RiTwitterXLine } from 'react-icons/ri'

const customerSupportLink = [
    {
        title: "FAQ",
        link: "/faq"
    },
    {
        title: "Terms and Conditions",
        link: "/customer-protection"
    }, {
        title: "Privacy Policy",
        link: "/policy"
    }, {
        title: "Refund Policy",
        link: "/policy"
    }, {
        title: "Customer Testimonials",
        link: "/testimonials"
    },
]

const companyInformationLink = [
    {
        title: "Mission and Vision",
        link: "#"
    },
    {
        title: "Awards and Recognitions",
        link: "#"
    }, {
        title: "Careers",
        link: "#"
    }
]
const quicksLink = [
    {
        title: "Home",
        link: "/"
    },
    {
        title: "Services",
        link: "/services"
    },
    {
        title: "Pricing",
        link: "#"
    },
    {
        title: "About Us",
        link: "/about"
    },
    {
        title: "Contact Us",
        link: "/contact"
    },
    {
        title: "Blog",
        link: "/blogs"
    }
]

const trustAndSecurityLink = [
    {
        title: "Data Security and Privacy",
        link: "#"
    },
    {
        title: "Secure Payment Methods",
        link: "https://razorpay.com/payment-gateway/#methods"
    },
    {
        title: "Customer Protection",
        link: "/customer-protection"
    },
    {
        title: "Insurance Coverage",
        link: "#"
    }
]
const socialMediaLinks = [
    {
        icons: <BsFacebook size="30" />,
        link: "https://www.facebook.com/people/Pack4movers/61550999278737/"
    },
    {
        icons: <BsInstagram size="30" />,
        link: "https://www.instagram.com/pack4movers/"
    }, {
        icons: <IoLogoYoutube size="30" />,
        link: "https://www.youtube.com/@Pack4Movers"
    }, {
        icons: <RiTwitterXLine size="30" />,
        link: "https://www.linkedin.com/in/mrunali-chowdhary-a7078a291/"
    },
]

export const Footer1 = () => {
    const date = new Date().getFullYear()
    return (
        <div className='w-full m-auto bg-[#002E5A] '>
            <div className='max-w-[1700px] w-11/12 m-auto py-10  text-white'>
                <div className=' flex flex-wrap gap-4 justify-between md:flex-col sm:flex-col '>
                    <div className='w-[42%] md:w-full sm:w-full flex flex-col gap-4'>
                        <div>
                            <h2 className=' text-lg sm:text-base font-bold mb-1'>PACK4MOVERS</h2>
                            <h5 className='text-sm'>The organization excels in providing comprehensive services encompassing the packing and transportation of residential and commercial properties, automobiles, and various logistical requirements. To achieve a transformation in its operations, enhance customer interactions, and attain higher efficiency and competitiveness, the company employs advanced technology and data-driven approaches.</h5>
                        </div>
                        <div className=' flex justify-between md:justify-start sm:justify-between md:gap-x-12 '>
                            <div className='w-[48%] md:w-full'>
                                <h2 className='text-lg sm:text-base font-bold mb-1'>CUSTOMER SUPPORT</h2>
                                <div className='flex flex-col text-sm sm:text-[13px]'>
                                    {
                                        customerSupportLink?.map((el, i) => (
                                            <Link to={el.link}>{el.title}</Link>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='w-[48%] md:w-full'>
                                <h2 className='text-lg sm:text-base font-bold mb-1'>COMPANY INFORMATION</h2>
                                <div className='flex flex-col text-sm sm:text-[13px]'>
                                    {
                                        companyInformationLink?.map((el, i) => (
                                            <Link to={el.link}>{el.title}</Link>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-[54%] flex justify-between md:w-full sm:w-full sm:gap-2'>
                        <div className='w-[48%] md:w-full'>
                            <h2 className='text-lg sm:text-base font-bold mb-1'>QUICK LINKS</h2>
                            <div className='flex flex-col text-sm sm:text-[13px]'>
                                {
                                    quicksLink?.map((el, i) => (
                                        <Link to={el.link}>{el.title}</Link>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='w-[48%] md:w-full'>
                            <h2 className='text-lg sm:text-base font-bold mb-1'>TRUST AND SECURITY</h2>
                            <div className='flex flex-col text-sm sm:text-[13px] self-start'>
                                {
                                    trustAndSecurityLink?.map((el, i) => (
                                        <Link to={el.link}>{el.title}</Link>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="text-center sm:hidden">
                            <h4 className="text-lg uppercase sm:text-lg font-bold mb-2">
                                Connect with Us
                            </h4>
                            <Link to={'/request-quote'}>
                                <button className="bg-[#ffb500] text-black hover:bg-white hover:text-[#ffb500]   uppercase font-semibold  px-6 py-2 md:px-2">
                                    CONTACT US
                                </button>
                            </Link>
                            <Link to={'tel:+91 87677 30916'}>
                                <p className="px-auto pt-2 ">+91 87677 30916</p>
                            </Link>
                            <Link to={'mailto:pack4movers.ie@gmail.com'}>
                                <p className="px-auto ">pack4movers.ie@gmail.com</p>
                            </Link>

                        </div>
                    </div>
                    <div className='hidden sm:block text-center'>
                        <h4 className="text-lg uppercase sm:text-lg font-bold mb-2">
                            Connect with Us
                        </h4>
                        <Link to={'/contact'}>
                            <button className="bg-[#ffb500] text-black hover:bg-white hover:text-[#ffb500]   uppercase font-normal  px-8 py-2 md:px-2">
                                CONTACT US
                            </button>
                        </Link>
                        <Link to={'tel:+91 87677 30916'}>
                            <p className="px-auto pt-2 ">+91 87677 30916</p>
                        </Link>
                        <Link to={'mailto:pack4movers.ie@gmail.com'}>
                            <p className="px-auto ">pack4movers.ie@gmail.com</p>
                        </Link>
                    </div>

                </div>
                <div className=' flex justify-between mt-8 md:flex-col md:justify-center md:gap-10 sm:flex-col sm:justify-center sm:gap-8'>
                    <div className='w-[66%] md:w-full sm:w-full'>
                        <div className='flex items-center sm:justify-center'>
                            <img src={Logo} alt="pack4movers logo" className='w-20 bg-white px-2' />
                            <div className='flex justify-evenly w-full ml-10 sm:hidden sm:text-[13px]'>
                                <p>Terms Of Use</p>
                                <Link to={'/policy'} >Privacy Policy</Link>
                                <p>Trademark</p>
                                <p>Lisence Agreement</p>
                            </div>
                        </div>
                        <div className='text-sm mt-4 sm:text-[13px]'>
                            Pack4 Movers is embarking on a holistic digital business transformation voyage to modernize and enhance its operations, guaranteeing its continued prominence in the field of packing and moving services.
                        </div>
                    </div>
                    <div className='hidden sm:flex flex-wrap justify-evenly w-full text-sm sm:text-[13px]'>
                        <p>Terms Of Use</p>
                        <Link to={'/policy'} >Privecy Policy</Link>
                        <p>Trademark</p>
                        <p>Lisence Agreement</p>
                    </div>
                    <div className='w-[30%] md:m-auto sm:auto md:w-[70%] sm:w-full flex flex-col justify-between md:text-center sm:text-center md:gap-6 sm:gap-4 '>
                        <div className='flex justify-between md:justify-evenly'>
                            {
                                socialMediaLinks?.map((el, i) => (
                                    <a href={el.link} key={i} className='text-white'>{el.icons}</a>
                                ))
                            }
                        </div>
                        <div className='text-sm'>Copyright © {date} All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
