import React, { useState } from 'react'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import PackyTruck from '../../../Assets/Images/landingpage/PackyTruck.png'
import bulbOn from '../../../Assets/CardImg/Landingpage/LightOn.svg'
import gear from '../../../Assets/CardImg/Landingpage/Gear.svg'
import phone from '../../../Assets/CardImg/Landingpage/SpeakerPhone.svg'
import thumbsUp from '../../../Assets/CardImg/Landingpage/thumbs-up.svg'


const cardArray = [
    {
        icon: bulbOn,
        number: 12000,
        desc: 'Customer-Centric Solutions',
        alt: 'Bulb'

    },
    {
        icon: phone,
        number: 9600,
        desc: 'Package Tracking',
        alt: 'Phone'

    },
    {
        icon: thumbsUp,
        number: 1500,
        desc: '24/7 customer Support',
        alt: "like Thumb"

    },
    {
        icon: gear,
        number: 60,
        desc: 'Enhanced Security',
        alt: "Gear"

    },
]


const OurWork = () => {
    const [trigger, setTrigger] = useState(false)
    return (
        <section className='relative bg-white py-10 sm:pt-4 xs:pt-4 overflow-x-hidden'>
            <div className='w-11/12 m-auto'>
                <div className='text-center w-9/12 m-auto sm:w-full'>
                    <h2 className='text-4xl text-[#002E5ADB] uppercase font-bold sm:text-2xl xs:text-2xl'
                        data-aos='fade-down'
                        data-aos-duration='500'
                    >
                        Our Work
                    </h2>
                    <h4 className='text-2xl font-semibold my-4 md:text-3xl sm:text-lg xs:text-lg'
                        data-aos='fade-up'
                        data-aos-duration='500'
                    >
                        Our goal is to offer a transparent, hassle-free, and top-notch relocation journey.

                    </h4>
                </div>
                <ScrollTrigger
                    onEnter={() => setTrigger(true)}
                >
                    <div className='flex justify-center items-center md:flex-col sm:flex-col'>
                        <div className='w-1/2 sm:w-full overflow-x-hidden'
                            data-aos='fade-right'
                            data-aos-duration='700'
                        >
                            <img src={PackyTruck} alt="PackyTruck" className='w-min' />
                        </div>
                        <div className='flex justify-evenly flex-wrap'>
                            {
                                cardArray?.map((ele, i) => <Card key={i} {...ele} trigger={trigger} />)
                            }
                        </div>
                    </div>
                </ScrollTrigger>
            </div>
        </section >
    )
}

export default OurWork

const Card = ({ icon, number, desc, alt, trigger }) => {
    return (
        <div className='w-5/12 text-white  bg-[#002E5A] border border-[#ffb500] flex justify-center items-center flex-col my-2 rounded-bl-2xl gap-2 shadow-lg py-4 md:w-full  sm:w-full'
        // data-aos='zoom-out-down'
        // data-aos-duration='500'

        >
            <img src={icon} alt={alt} className='w-20' />
            {/* <p className='font-semibold text-2xl'>
                {
                    trigger &&
                    < CountUp start={0} end={number} duration={5} delay={0} />
                }+
            </p> */}
            <p className='uppercase'>{desc}</p>
        </div>
    )
}