import React from 'react'

import cardImg1 from '../../../Assets/CardImg/Blog/dfsadfd.png'
import cardImg2 from '../../../Assets/CardImg/Blog/5.png'
import cardImg3 from '../../../Assets/CardImg/Blog/3.png'
import Card from './Card/Card'

const cardArray = [
    {
        img: cardImg1,
        title: "Understanding Moving Insurance: What You Need to Know:",
        date: "April 18 2023"
    },
    {
        img: cardImg2,
        title: "Eco-Friendly Moving: Tips for Sustainable Relocation",
        date: "April 20 2023"
    },
    {
        img: cardImg3,
        title: "Moving Your Office: Tips for a Smooth Transition",
        date: "April 22 2024"
    },
]

const LatestNews = () => {
    return (
        <section className='bg-white py-10 overflow-x-hidden'>
            <div className="w-10/12 m-auto md:w-11/12 sm:w-11/12">
                <div className='text-center w-10/12 m-auto sm:w-full'>
                    <h3 className='text-2xl font-semibold text-[#002E5A]'
                        data-aos='fade-down'
                        data-aos-duration='800'
                    >
                        LATEST NEWS
                    </h3>
                    <h6 className='text-5xl font-medium my-2 md:text-3xl sm:text-2xl'
                        data-aos='fade-up'
                        data-aos-duration='800'
                    >
                        Want To Learn More About Your Moving Budget
                    </h6>
                </div>
                <div className='w-full mt-5  flex justify-between items-center flex-wrap sm:flex-col'
                    data-aos='zoom-in'
                    data-aos-duration='800'
                >
                    {
                        cardArray?.map((ele, i) => <Card key={i} {...ele} />)
                    }

                </div>
            </div>

        </section>
    )
}

export default LatestNews