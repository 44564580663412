import React, { useEffect, useState } from 'react'
import { supabase } from '../../Supabase/Config'
import { Pagination } from './Pagination'
import { Oval } from 'react-loader-spinner'

export const NewsletterTable = () => {
    const [newsLetterData, setNewsLetterData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [pageNum, setPageNum] = useState(1)
    const paginationValue = 10;
    const [totalData, setTotalData] = useState([])
    const noOfPages = Math.ceil(totalData.length / paginationValue)

    const getData = async () => {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('News_letter_Data')
                .select('*')
                .order('id', { ascending: false })
            if (data) {
                setNewsLetterData(data)
                setIsLoading(false)

            }
            if (error) {
                setIsLoading(false)
                console.log("ERRR", error)
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getData();
    }, [])

    const getTotalFormData = async () => {
        try {
            const { data, error } = await supabase
                .from('News_letter_Data')
                .select('*')
                .order('id', { ascending: false })
                .range(paginationValue * pageNum - paginationValue, paginationValue * pageNum - 1)

            if (data) {
                setTotalData(data)
            }

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getTotalFormData();
    }, [])


    return (
        <div>
            {
                isLoading ?
                    (<div className='flex justify-center items-center h-[40vh]'>
                        <Oval
                            height={80}
                            width={80}
                            color="#ffb500"
                            wrapperStyle={{}}
                            wrapperclassName=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#ffb500"
                            strokeWidth={5}
                            strokeWidthSecondary={2}

                        />
                    </div>)
                    :
                    <>
                        {
                            newsLetterData.length > 0
                                ?
                                <>
                                    <div className="w-8/12 md:w-11/12 sm:w-11/12 m-auto py-8 ">
                                        <div className="shadow md:overflow-x-scroll sm:overflow-x-scroll rounded border-b border-gray-200">
                                            <table className="min-w-full bg-white">
                                                <thead className="bg-gray-800 text-white">
                                                    <tr >
                                                        <th className="w-24 text-left py-3 px-4 uppercase font-semibold text-sm">Sr. No.</th>
                                                        <th className="w-24 text-left py-3 px-4 uppercase font-semibold text-sm">Id</th>
                                                        <th className=" text-left py-3 px-4 uppercase font-semibold text-sm">Email Id</th>
                                                        <th className="w-32 text-left py-3 px-4 uppercase font-semibold text-sm">Date</th>


                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-700">
                                                    {
                                                        newsLetterData.length && newsLetterData?.map((el, i) => (
                                                            <tr className={`border-b text-sm text-black ${i % 2 == 0 ? "bg-slate-200" : ""}`}>
                                                                <td className=" text-left py-3 px-4">{i + 1 + paginationValue * pageNum - paginationValue}</td>
                                                                <td className=" text-left py-3 px-4">{el.id}</td>
                                                                {/* <td className=" text-left py-3 px-4">{el.fullName}</td> */}
                                                                <td className=" text-left py-3 px-4">{el.emailId}</td>

                                                                <td className="w-32 text-left py-3 px-4">{new Date(el.created_at.split(" ")[0]).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' })}</td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <Pagination pageNum={pageNum} setPageNum={setPageNum} paginationValue={paginationValue} noOfPages={noOfPages} />
                                </>
                                :
                                <div className='h-[40vh] flex items-center justify-center'>
                                    <h4 className=' text-3xl font-semibold text-center'>No Data</h4>
                                </div>
                        }
                    </>
            }
            {/* <div ><CompletedModal open={open} setOpen={setOpen} movingDataId={movingDataId} handleAddTaskComplete={handleAddTaskComplete} /></div> */}

        </div >
    )
}
