import React, { useEffect } from 'react'

import styles from '../components/LandingPage/style.module.css'
import RequestQuate from '../components/RequestQuate/RequestQuate'
import Herosection from '../components/RequestQuote/Herosection/Herosection'
import GetQuote from '../components/LandingPage/GetQuote/GetQuote'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const RequestQuatePage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Request Quote Page" });
        window.scroll(0, 0)
      }, [])
    return (
        <div className={styles.herosection__background}>
            <Herosection />
            <GetQuote bg />
            <RequestQuate />
        </div>
    )
}

export default RequestQuatePage