import React, { useEffect, useState } from 'react'

import Img1 from '../../../Assets/CardImg/Landingpage/icon packer 4.png'
import Img2 from '../../../Assets/CardImg/Landingpage/icon packer 1.png'
import Img3 from '../../../Assets/CardImg/Landingpage/Untitled design 4.png'
import Img4 from '../../../Assets/CardImg/Landingpage/icon packer 3.png'
import Img5 from '../../../Assets/CardImg/Landingpage/icon packer 5.png'

import { supabase } from '../../../Supabase/Config'


const cardArray = [
    {
        img: Img1,
        desc: "Local Moving",
    },
    {
        img: Img2,
        desc: "Car Moving",
    },
    {
        img: Img3,
        desc: "Shared Storage Facility",
    },
    {
        img: Img4,
        desc: "International",
    },
    {
        img: Img5,
        desc: "Office Moving",
    }
]

const GetQuote = ({ bg }) => {

    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        emailId: '',
        fromPickUp: '',
        toDeliver: '',
    })
    const [alert, setAlert] = useState('')
    const [message, setMessage] = useState('')
    const [hide, setHide] = useState(false)
    const [activeCard, setActiveCard] = useState('Local Moving')

    const handleActive = (desc) => {
        setActiveCard(desc)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData)
        // console.log('submit')
        try {
            const { error } = await supabase
                .from('Get_Quote_Form_Data')
                .insert({ ...formData, category: activeCard })

            if (error) {
                setAlert('error')
                setMessage('Error! Your Request is failed')
                setHide(true)
            } else {
                setAlert('success')
                setMessage('Your Request was successfully')
                setHide(true)
                setFormData({
                    fullName: '',
                    mobileNumber: '',
                    emailId: '',
                    fromPickUp: '',
                    toDeliver: '',
                })
            }

        } catch (error) {
            setAlert('error')
            setHide(true)
            setMessage(error.message)
        }

        setTimeout(() => {
            setHide(false)
        }, 2000)

    }
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (

        <div className='relative  overflow-x-hidden  mb-0'>
            <div className={`${bg && 'bg-[#002E5ADB]/80 md:bg-white sm:bg-white'} `} >

                <div data-aos='fade-right'
                    data-aos-duration='700'
                    data-aos-offset='0'
                >
                    <div className='w-10/12 m-auto bg-[#052D51] rounded-t-xl p-3 pt-5 px-5 flex text-white sm:w-full xs:w-full sm:rounded-none xs:rounded-none' id='upperDiv'
                    // data-aos='fade-right'
                    // data-aos-duration='700'

                    >
                        <h2 className='text-3xl w-1/3 font-semibold md:hidden sm:hidden'>
                            <span className=' opacity-70 mr-2'>
                                Get a free quote for your
                            </span>
                            {activeCard}.
                        </h2>
                        <div className='flex flex-wrap justify-evenly m-0 w-full gap-y-2'>
                            {
                                cardArray?.map((ele, i) => <Card key={i} {...ele}
                                    activeCard={activeCard}
                                    handleActive={handleActive} />)
                            }
                        </div>
                    </div>

                    <div className={`${bg && 'bg-white pb-10'} `}>
                        <div className='w-10/12 sm:w-full m-auto bg-[#052D51] p-3 py-5 px-5 rounded-b-xl sm:rounded-none xs:rounded-none'
                        // data-aos='fade-right'
                        // data-aos-duration='700'
                        >
                            <h2 className='text-xl font-semibold hidden md:block sm:block mb-3' style={{ color: 'white' }}>
                                Get a free quote for your {activeCard}.
                            </h2>
                            <form onSubmit={handleSubmit} >
                                <div className='w-full flex justify-evenly flex-wrap md:gap-3 sm:gap-3'>
                                    <div className='w-[32%] md:w-full sm:w-full'>
                                        <input value={formData.fullName} type="text" name='fullName' className='w-full sm:w-full p-2 border border-gray-300 rounded-md shadow-lg' placeholder='Full Name*' required onChange={handleChange} />
                                    </div>
                                    <div className='w-[32%] md:w-full sm:w-full'>
                                        <input value={formData.mobileNumber} type="tel" name='mobileNumber' className='w-full sm:w-full p-2 border border-gray-300 rounded-md shadow-lg' placeholder='Mobile number* ' required pattern='[0-9]{10}' onChange={handleChange} />
                                    </div>
                                    <div className='w-[32%] md:w-full sm:w-full'>
                                        <input value={formData.emailId} type="email" name='emailId' className=' w-full sm:w-full p-2 border border-gray-300 rounded-md shadow-lg' placeholder='Email Address*' required onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='w-full flex justify-evenly flex-wrap md:gap-3 sm:gap-3 mt-4'>

                                    <div className='w-[28%] lg:w-[23%] md:w-full sm:w-full'>
                                        <input value={formData.fromPickUp} type="text" name='fromPickUp' className=' w-full sm:w-full p-2 border border-gray-300 rounded-md shadow-lg' placeholder='From *' required onChange={handleChange} />
                                    </div>
                                    <div className="w-[28%] lg:w-[23%] md:w-full sm:w-full">
                                        <input value={formData.toDeliver} type="text" name='toDeliver' className='w-full sm:w-full p-2 border border-gray-300 rounded-md shadow-lg' placeholder='To *' required onChange={handleChange} />
                                    </div>
                                    <div className='md:w-full sm:w-full flex justify-evenly flex-wrap' >
                                        {/* <input type="text" className='w-[35%] p-1 border border-gray-300 rounded-md shadow-lg sm:w-1/2' placeholder='Enter Captcha *' required />
                                <p className='bg-black py-2 px-8 rounded-lg'>
                            </p> */}
                                        <button type='submit' className='bg-[#ffb500] py-2 px-8 rounded-lg text-black font-medium sm:my-2'>
                                            Submit
                                        </button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div>

        </div >
    )
}

export default GetQuote

const Card = ({ img, desc, activeCard, handleActive }) => {

    // console.log("ACTIVE", activeCard)

    return (
        <div >
            {/* {
                desc === activeCard ?
                    <div className={`${desc === activeCard && "bg-white brightness-100 mt-0 md:-mt-4 sm:-mt-2 xs:-mt-2 rounded-t-lg w-full text-center text-sm font-semibold text-black"}`}>
                        Selected
                    </div>
                    : ""
            } */}
            {/* {
                desc === activeCard ? (<p className='absolute text-sm font-semibold text-center'>
                    Selected
                </p>) : ""
            } */}
            <div >
                <div className={`relative w-[7rem] h-28 cursor-pointer sm:h-[6.5rem] xs:h-[6.5rem] flex flex-col justify-between items-center shadow-xl  rounded-lg md:my-2 sm:my-2 sm:w-24 md:gap-0 sm:gap-0 text-black ${desc === activeCard ? " bg-[#ffb500] gap-" : "bg-white"}`} onClick={() => handleActive(desc)}
                >
                    {
                        desc === activeCard &&
                        <div className={`${desc === activeCard && "bg-white brightness-100 py-1 rounded-t-lg w-full text-center text-sm font-semibold text-black"}`}>
                            Selected
                        </div>
                    }
                    <div className={`p-2 sm:p-1  flex flex-col items-center 
                    `}>
                        <div>
                            <img src={img} alt={desc} className='h-[3.8rem] self-center' />
                        </div>
                        {
                            desc !== activeCard &&
                            <div className='pb-1 flex justify-center flex-wrap items-center sm:pb-0'>
                                <p className='text-xs text-center md:text-[10px] md:leading-3 sm:text-[10px] sm:leading-3 sm:mt-1'>{desc}</p>
                            </div>
                        }
                    </div>


                    {/* {
                        desc === activeCard ?
                            <div className={`${desc === activeCard && "bg-[#ffb500] mt-0 md:-mt-3 sm:-mt-2 xs:-mt-2 rounded-b-lg w-full text-center text-sm font-semibold text-black"}`}>
                                Selected
                            </div>
                            : ""
                    } */}
                </div>
            </div>


        </div>
    )
}